import React from 'react';
import { PrismicField } from 'types';
import { RichText } from 'prismic-reactjs';
import styled from 'styled-components';
import { H1, H2, H3, H4, H5 } from '@tuunetech/tuune-components';
import { commonHtmlSerializer } from './commonHtmlSerializer';

const Container = styled.div`
  ${H1}, ${H2}, ${H3}, ${H4}, ${H5} {
    margin-bottom: 16px;
  }
`;

export const ContentRender: React.FC<{ content?: PrismicField }> = ({
  content,
}) => {
  return (
    <Container>
      <RichText render={content?.raw} htmlSerializer={commonHtmlSerializer} />
    </Container>
  );
};
