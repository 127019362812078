import React from 'react';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { BannerPrimary, SliceTypes } from 'types';
import { BannerContainer, Content, Hero, Title } from './styles';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { ButtonLink } from '../../shared/ButtonLink';

export const Banner: React.FC<BannerPrimary & { className?: string }> = ({
  title,
  text_color,
  content,
  hero,
  button_color,
  button_link,
  button_text,
  button_variant,
  onClick,
  className,
}) => {
  const isXXL = useMediaQuery((theme: Theme) => theme.breakpoints.up(2600));
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(768));
  const imageXl = getImage(hero);
  const imageXxl = getImage(hero.thumbnails.xxl);
  const imageLg = getImage(hero.thumbnails.lg);
  const imageMd = getImage(hero.thumbnails.md);
  const imageSm = getImage(hero.thumbnails.sm);
  const imageXs = getImage(hero.thumbnails.xs);

  const heroImage = isXXL
    ? imageXxl
    : isXL
    ? imageXl
    : isLG
    ? imageLg
    : isMD
    ? imageMd
    : isSM
    ? imageSm
    : imageXs;

  return (
    <BannerContainer $color={text_color} className={className}>
      {heroImage && <Hero alt="banner" image={heroImage} />}
      <Content fixed={true} maxWidth="lg">
        <Box maxWidth="554px" width="100%">
          {title.text && <Title>{title.text}</Title>}
          <RichText
            render={content?.raw}
            htmlSerializer={commonHtmlSerializer}
          />
          {button_link?.url && button_text && (
            <>
              <Box height={`${isMD ? 55 : 24}px`} />
              <ButtonLink
                url={button_link.url}
                target={button_link.target}
                text={button_text}
                sliceName={SliceTypes.Banner}
                variant={button_variant}
                color={button_color}
                fullWidth={!isMD}
                onClick={onClick}
              />
            </>
          )}
        </Box>
      </Content>
    </BannerContainer>
  );
};
