import styled from 'styled-components';
import { BODY1 } from '@tuunetech/tuune-components';

export const ScrollableContainer = styled.div`
  width: 100%;
  margin-bottom: 40px;
  overflow-x: hidden;
`;

export const SymptomsWrapper = styled.div`
  display: flex;
  width: fit-content;
  user-select: none;
  cursor: grab;
`;

export const SymptomBlock = styled.div`
  min-width: 160px;
  min-height: 160px;
  z-index: -1;

  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  margin-right: 16px;

  ${BODY1} {
    position: absolute;
    text-align: center;
    color: ${({ theme }) => theme.palette.common.white};
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    width: 200px;
    height: 320px;
    margin-right: 40px;
  }
`;
