import React from 'react';
import { Slices, SliceTypes } from '../../types';
import {
  AlternateTiles,
  Banner,
  CallToAction,
  KeyValues,
  Testimonials,
  Wrapper,
} from './styles';
import { SliceWrapper } from '../shared/SliceWrapper';

type SupportProgramProps = {
  slices: Slices;
};

export const SupportProgram: React.FC<SupportProgramProps> = ({ slices }) => {
  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.CTA:
        return <CallToAction {...slice.primary} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.Testimonials:
        return <Testimonials {...slice.primary} testimonials={slice.items} />;
      default:
        return undefined;
    }
  };

  return (
    <Wrapper>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </Wrapper>
  );
};
