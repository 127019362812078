import styled from 'styled-components';
import { Banner as BaseBanner } from '../slices/Banner';
import {
  PrimaryButtonColorCss,
  YellowButtonColorCss,
} from '../../utils/GlobalStyle';
import { AlternateTiles as BaseAlternateTiles } from '../slices/AlternateTiles';
import { HowItWorks as BaseHowItWorks } from '../slices/HowItWorks';
import {
  Avatar,
  ItemsContainer,
  ListItemIndicator,
} from '../slices/HowItWorks/styles';
import { BODY1, H2, Container } from '@tuunetech/tuune-components';
import { KeyValues as BaseKeyValues } from '../slices/KeyValues';
import { Icon } from '../slices/KeyValues/styles';
import { Promo as BasePromo } from '../slices/Promo';
import { Text as BaseText } from '../slices/Text';
import { Team as BaseTeam } from '../slices/Team';
import { Person as BasePerson } from '../slices/Person';
import { TeamContainer } from '../slices/Team/styles';

export const Banner = styled(BaseBanner)`
  ${YellowButtonColorCss};
`;

export const AlternateTiles = styled(BaseAlternateTiles)`
  img {
    border-radius: 40px;
  }

  em {
    display: block;
    margin-top: 36px;
    font-style: normal;
    font-size: 12px;
    opacity: 0.5;
  }
`;

export const HowItWorks = styled(BaseHowItWorks)`
  ${PrimaryButtonColorCss};

  ${ListItemIndicator} {
    display: none;
  }

  ${ItemsContainer} {
    flex-direction: column;
    margin: 0 auto;
    .MuiTypography-root {
      text-align: center;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${ItemsContainer} {
      flex-direction: row;
    }
  }

  ${Avatar} {
    width: 168px;
    height: 168px;
    overflow: visible;
    img {
      border-radius: 50%;
      box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
        0 4px 5px rgba(0, 0, 0, 0.14), 0 1px 10px rgba(0, 0, 0, 0.12);
    }
  }

  ${BODY1}:first-of-type + ${BODY1} {
    text-align: left;
    margin-top: 8px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${H2} {
      text-align: left;
    }
  }
`;

export const KeyValues = styled(BaseKeyValues)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${Icon} {
      width: 48px;
      min-width: 48px;
      height: 48px;
    }
  }
`;

export const Promo = styled(BasePromo)`
  ${YellowButtonColorCss};
`;

export const Text = styled(BaseText)`
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: center;
  }

  ${Container} {
    max-width: 876px;
  }
`;

export const Team = styled(BaseTeam)`
  ${PrimaryButtonColorCss};

  ${TeamContainer} {
    padding-top: 20px;
  }
`;

export const Person = styled(BasePerson)`
  padding-bottom: 0;

  a {
    font-size: inherit;
    color: inherit;
    font-weight: bold;
  }
`;
