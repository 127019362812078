import React from 'react';
import { PromoPrimary, SliceTypes } from 'types';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { PromoContainer, Content, PromoOuterContainer } from './styles';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { ButtonLink } from '../../shared/ButtonLink';

export const Promo: React.FC<PromoPrimary & { className?: string }> = ({
  image,
  text_align,
  content,
  content_color,
  button_variant,
  button_color,
  button_link,
  button_text,
  content_position,
  linear_gradient,
  bg_color,
  onClick,
  className,
}) => {
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(600));
  const imageXl = getImage(image);
  const imageLg = getImage(image.thumbnails.lg);
  const imageMd = getImage(image.thumbnails.md);
  const imageSm = getImage(image.thumbnails.sm);
  const imageXs = getImage(image.thumbnails.xs);

  const promoImage = isXL
    ? imageXl
    : isLG
    ? imageLg
    : isMD
    ? imageMd
    : isSM
    ? imageSm
    : imageXs;

  return (
    <PromoOuterContainer
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <PromoContainer
              $contentPositionTop={content_position}
              $withBg={!!linear_gradient}
            >
              {promoImage && <GatsbyImage alt={image.alt} image={promoImage} />}
              <Content $color={content_color} $textAlign={text_align}>
                <RichText
                  render={content?.raw}
                  htmlSerializer={commonHtmlSerializer}
                />
                {button_link?.url && button_text && (
                  <Box display="flex" justifyContent="center">
                    <ButtonLink
                      url={button_link.url}
                      target={button_link.target}
                      color={button_color}
                      variant={button_variant}
                      fullWidth={!isMD}
                      text={button_text}
                      sliceName={SliceTypes.Promo}
                      onClick={onClick}
                    />
                  </Box>
                )}
              </Content>
            </PromoContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </PromoOuterContainer>
  );
};
