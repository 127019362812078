import styled from 'styled-components';
import { PrimaryButtonColorCss } from '../../../utils/GlobalStyle';

export const CardsContainer = styled.div`
  display: flex;
  column-gap: 24px;
  justify-content: center;
`;

export const Card = styled.div<{ $best: boolean }>`
  background-color: ${({ $best }) => ($best ? '#FFF4E5' : '#F7F4FB')};
  padding: 32px 24px 24px;
  border-radius: 4px;

  box-shadow: 0 149.67px 87.4072px rgba(18, 25, 34, 0.03),
    0 74.908px 43.7463px rgba(18, 25, 34, 0.02281),
    0 45.1211px 26.3507px rgba(18, 25, 34, 0.0195477),
    0 28.916px 16.8869px rgba(18, 25, 34, 0.0171268),
    0 18.741px 10.9447px rgba(18, 25, 34, 0.015),
    0 11.7972px 6.88959px rgba(18, 25, 34, 0.0128732),
    0 6.77825px 3.9585px rgba(18, 25, 34, 0.0104523),
    0 2.98328px 1.74224px rgba(18, 25, 34, 0.00719002);

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 300px;
  }

  ${PrimaryButtonColorCss};
`;

export const CardHeader = styled.div<{ $best: boolean }>`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  ::after {
    content: ' ';
    display: ${({ $best }) => ($best ? 'none' : 'block')};
    height: 1px;
    width: 80px;
    margin-top: 24px;
    background-color: #d4b3fe;
    margin-bottom: 40px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      margin-top: 32px;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    align-items: center;
    row-gap: 32px;
  }
`;

export const BestChip = styled.div`
  background-color: #fcb657;
  display: flex;
  align-items: center;
  padding: 7px 12px;
  border-radius: 40px;
  margin-top: 8px;
  margin-bottom: 24px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 16px;
  }
  .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 8px;
  }
`;

export const Feature = styled.div<{ $isIncluded: boolean }>`
  display: flex;
  min-height: 48px;
  align-items: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: center;
  }

  color: ${({ $isIncluded }) => ($isIncluded ? '#000000' : '#D4B3FE')};
`;

export const FeatureList = styled.div`
  display: flex;
  white-space: pre-wrap;

  flex-direction: column;
  text-align: end;
  row-gap: 24px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    row-gap: 32px;
  }
  max-width: 300px;

  padding-top: 207px;
`;

export const TabLabel = styled.span`
  text-transform: none;
  letter-spacing: normal;
`;
