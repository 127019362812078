import styled from 'styled-components';
import { BODY1 } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const WiwWrapper = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  width: 100%;
  ${GradientOrSolidBgCss};
`;

export const Item = styled.div`
  & + & {
    margin-top: 48px;
  }
`;

export const ImageWrap = styled.div`
  :first-of-type,
  :first-of-type + ${BODY1} {
    margin-top: 24px !important;
  }
  img {
    width: 48px;
    height: 48px;
  }
`;

export const List = styled.div`
  ${BODY1} {
    width: 100%;
  }
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  ${ImageWrap} + ${BODY1} {
    margin: 6px 0 6px 12px;
    display: inline-block;
    width: calc(100% - 60px);
  }
`;
