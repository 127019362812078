import React from 'react';
import { SliceTypes, StepGuideItems, StepGuidePrimary } from '../../../types';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { Header, StepGuideContainer, StepIndicator } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';

export const StepGuide: React.FC<
  StepGuidePrimary & { steps?: StepGuideItems[]; className?: string }
> = ({
  content,
  steps,
  button_text,
  button_color,
  button_variant,
  button_link,
  bg_color,
  className,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <StepGuideContainer $bgColor={bg_color} className={className}>
      <Container maxWidth="lg" $verticalPadding={64}>
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <Header>
              <RichText
                htmlSerializer={commonHtmlSerializer}
                render={content.raw}
              />
            </Header>
            {steps?.length && (
              <Box
                marginTop="40px"
                display="flex"
                flexDirection="column"
                width={isDesktop ? 'fit-content' : '100%'}
                marginX="auto"
              >
                {steps?.map(({ content }, idx) => (
                  <Box
                    key={content.text}
                    display="flex"
                    padding="16px"
                    width="fit-content"
                  >
                    <StepIndicator>{idx + 1}</StepIndicator>
                    <RichText
                      render={content.raw}
                      htmlSerializer={commonHtmlSerializer}
                    />
                  </Box>
                ))}
              </Box>
            )}
            {button_text && button_link?.url && (
              <Box display="flex" justifyContent="center" marginTop="40px">
                <ButtonLink
                  url={button_link.url}
                  target={button_link.target}
                  text={button_text}
                  sliceName={SliceTypes.StepGuide}
                  color={button_color}
                  variant={button_variant}
                  fullWidth={!isDesktop}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </StepGuideContainer>
  );
};
