import styled from 'styled-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const TestimonialsContainer = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  width: 100%;

  padding: ${({ $bgColor, $gradient }) =>
    $bgColor || $gradient ? '64px 0' : '0'};
`;
