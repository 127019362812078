import React, { useCallback, useMemo } from 'react';
import { OptionsItems, OptionsPrimary, SliceTypes } from 'types';
import {
  CAPTION,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Hidden, Theme, useMediaQuery } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import {
  OptionsContainer,
  Option,
  DesktopOptionsWrapper,
  MobileOptionsWrapper,
  OptionsBlock,
} from './styles';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { ButtonLink } from '../../shared/ButtonLink';

export const Options: React.FC<
  OptionsPrimary & { options?: OptionsItems[]; className?: string }
> = ({
  options,
  content,
  className,
  linear_gradient,
  bg_color,
  button_variant,
  button_color,
  button_text,
  button_link,
}) => {
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const renderOption = useCallback(option => {
    const iconImage = getImage(option.icon);
    return (
      <Option key={option.name}>
        {iconImage && <GatsbyImage alt={option.icon.alt} image={iconImage} />}
        <CAPTION $marginTop={12}>{option.name}</CAPTION>
      </Option>
    );
  }, []);

  const OptionsList = useMemo(() => options?.map(renderOption) || [], [
    options,
  ]);

  return (
    <OptionsContainer
      className={className}
      $bgColor={bg_color}
      $gradient={linear_gradient}
    >
      <Container $marginBottom={24} maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <RichText
              render={content?.raw}
              htmlSerializer={commonHtmlSerializer}
            />
          </GridItem>
        </GridContainer>
      </Container>
      <Hidden smDown>
        <Container maxWidth="lg">
          <GridContainer justifyContent="center">
            <GridItem xs={12} md={10} xl={12}>
              <DesktopOptionsWrapper>{OptionsList}</DesktopOptionsWrapper>
            </GridItem>
          </GridContainer>
        </Container>
      </Hidden>
      <Hidden mdUp>
        <MobileOptionsWrapper>
          <OptionsBlock $optionsCount={options?.length || 0}>
            {[...OptionsList, ...OptionsList, ...OptionsList]}
          </OptionsBlock>
        </MobileOptionsWrapper>
      </Hidden>
      {button_link?.url && button_text && (
        <Container>
          <Box
            marginTop={`${isMD ? 55 : 24}px`}
            display="flex"
            justifyContent="center"
          >
            <ButtonLink
              url={button_link.url}
              target={button_link.target}
              text={button_text}
              sliceName={SliceTypes.Banner}
              variant={button_variant}
              color={button_color}
              fullWidth={!isMD}
            />
          </Box>
        </Container>
      )}
    </OptionsContainer>
  );
};
