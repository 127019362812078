import React, { useEffect } from 'react';

import { Wrapper } from '../SupportProgram/styles';
import { SliceWrapper } from '../shared/SliceWrapper';
import { Slices, SliceTypes } from '../../types';
import { Purchase } from '../slices/Purchase';
import { HowItWorks } from '../BCLanding/styles';
import {
  CallToAction,
  CustomTestimonials,
  Influencer,
  StepGuide,
  Testimonials,
} from '../HTLanding/styles';
import { FAQ } from '../slices/FAQ';
import { KeyValues } from '../slices/KeyValues';
import { Options } from '../slices/Options';
import { AlternateTiles } from '../slices/AlternateTiles';
import { ContentTabs } from '../slices/ContentTabs';
import {
  ECOMMERCE_HORMONAL_WELLBEING_PRODUCT,
  ENHANCED_ECOMMERCE_EVENT,
} from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

type HormonalWellbeingProps = {
  slices: Slices;
};

export const HormonalWellbeing: React.FC<HormonalWellbeingProps> = ({
  slices,
}) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.logEnhancedEcommerceEvent(
      ENHANCED_ECOMMERCE_EVENT.VIEW_ITEM,
      ECOMMERCE_HORMONAL_WELLBEING_PRODUCT,
    );
  }, [analytics]);

  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Purchase:
        return <Purchase {...slice.primary} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.StepGuide:
        return <StepGuide {...slice.primary} steps={slice.items} />;
      case SliceTypes.FAQ:
        return <FAQ {...slice.primary} qas={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.Options:
        return <Options {...slice.primary} options={slice.items} />;
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.ContentTabs:
        return <ContentTabs {...slice.primary} tabs={slice.items} />;
      case SliceTypes.CTA:
        return <CallToAction {...slice.primary} />;
      case SliceTypes.Influencer:
        return <Influencer {...slice.primary} />;
      case SliceTypes.Testimonials:
        const testimonial = slice.items ? slice.items[0] : undefined;
        return testimonial?.name ? (
          <Testimonials {...slice.primary} testimonials={slice.items} />
        ) : (
          <CustomTestimonials {...slice.primary} testimonials={slice.items} />
        );
    }
  };

  return (
    <Wrapper>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </Wrapper>
  );
};
