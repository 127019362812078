import React from 'react';
import { TeamItems, TeamPrimary } from 'types';
import {
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { getImage } from 'gatsby-plugin-image';
import { TitleParagraphsGroup } from 'components/shared';
import { TeamContainer, Members, Member, Wrapper } from './styles';
import { navigate } from 'gatsby-link';
import { getUrl } from 'utils/helpers';

export const Team: React.FC<
  TeamPrimary & { teamMembers?: TeamItems[]; className?: string }
> = ({
  title,
  is_scrollable,
  paragraphs,
  teamMembers,
  button_text,
  button_link,
  button_color,
  button_variant,
  className,
  bg_color,
  linear_gradient,
}) => {
  const TeamMembers = teamMembers?.map(
    ({ name, role, description, avatar, avatar: { alt } }) => {
      const image = getImage(avatar);
      return (
        <Member
          key={alt}
          name={name}
          role={role}
          description={description.raw}
          image={image}
          alt={alt}
        />
      );
    },
  );

  return (
    <Wrapper
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg">
        <TeamContainer>
          <GridContainer justifyContent="center">
            <GridItem xs={12} md={10} lg={8} xl={6}>
              <TitleParagraphsGroup
                title={title.raw}
                paragraphs={paragraphs.raw}
              />
            </GridItem>
          </GridContainer>
          {is_scrollable ? null : <Members>{TeamMembers}</Members>}
          {button_link.url && button_text && (
            <GridContainer justifyContent="center">
              <GridItem>
                <Button
                  variant={button_variant}
                  color={button_color}
                  onClick={() => navigate(getUrl(button_link.url))}
                >
                  {button_text}
                </Button>
              </GridItem>
            </GridContainer>
          )}
        </TeamContainer>
      </Container>
    </Wrapper>
  );
};
