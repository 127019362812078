import React from 'react';
import { Slices, SliceTypes } from '../../types';
import { Wrapper } from '../SupportProgram/styles';
import { SliceWrapper } from '../shared/SliceWrapper';
import {
  AlternateTiles,
  Banner,
  HowItWorks,
  KeyValues,
  Person,
  Promo,
  Team,
  Text,
} from './styles';
import { QuoteTestimonials } from '../slices/Testimonials/QuoteTestimonials';
import { PricingTable } from '../slices/PricingTable';

type HolisticVPProps = {
  slices: Slices;
};

export const HolisticVP: React.FC<HolisticVPProps> = ({ slices }) => {
  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.Testimonials:
        return (
          <QuoteTestimonials {...slice.primary} testimonials={slice.items} />
        );
      case SliceTypes.Promo:
        return <Promo {...slice.primary} />;
      case SliceTypes.Text:
        return <Text {...slice.primary} />;
      case SliceTypes.Team:
        return <Team {...slice.primary} teamMembers={slice.items} />;
      case SliceTypes.Person:
        return <Person {...slice.primary} />;
      case SliceTypes.PricingTable:
        return <PricingTable {...slice.primary} products={slice.items} />;
      default:
        return undefined;
    }
  };

  return (
    <Wrapper>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </Wrapper>
  );
};
