import React, { useMemo } from 'react';
import {
  PricingTableItems,
  PricingTablePrimary,
  SliceTypes,
} from '../../../types';
import {
  BODY1,
  CAPTION,
  Container,
  ContentBlock,
  H3,
} from '@tuunetech/tuune-components';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { Box, Tab, Tabs, Theme, useMediaQuery } from '@material-ui/core';
import {
  BestChip,
  Card,
  CardContent,
  CardHeader,
  CardsContainer,
  Feature,
  FeatureList,
  TabLabel,
} from './styles';
import { Check, Close, StarBorder } from '@material-ui/icons';
import { ButtonLink } from '../../shared/ButtonLink';
import { CONTENT_TAB_ITEM_CLICK } from '../../../utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

export const PricingTable: React.FC<
  PricingTablePrimary & { products: PricingTableItems[]; className?: string }
> = ({ content, features, products }) => {
  const analytics = useAnalytics();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('sm'),
  );

  const [value, setValue] = React.useState(products.length - 1);
  const handleTabChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    analytics.track(CONTENT_TAB_ITEM_CLICK, {
      tab_title: products?.[newValue].product_name.text,
      slice_name: SliceTypes.PricingTable,
    });
    setValue(newValue);
  };

  const remappedFeatures = useMemo(() => {
    return features.raw.map(feature => feature.text);
  }, [features]);

  const remappedProductFeatures = useMemo(() => {
    return products.map(product => {
      const productF = product.features.raw.map(feature => feature.text);
      return remappedFeatures.map(feature => productF.includes(feature));
    });
  }, [remappedFeatures]);

  const cards = useMemo(() => {
    return products.map((product, productIdx) => (
      <Card key={product.price} $best={product.best}>
        <CardHeader $best={product.best}>
          <RichText
            render={product.product_name.raw}
            htmlSerializer={commonHtmlSerializer}
          />
          <H3>${product.price}</H3>
          {product.best && (
            <BestChip>
              <StarBorder />
              <CAPTION>Best Value</CAPTION>
            </BestChip>
          )}
        </CardHeader>
        <CardContent>
          {remappedFeatures.map((feature, featureIdx) => {
            const isIncluded = remappedProductFeatures[productIdx][featureIdx];
            const Icon = isIncluded ? <Check /> : <Close />;
            return (
              <Feature $isIncluded={isIncluded} key={feature}>
                {isDesktop ? (
                  Icon
                ) : (
                  <ContentBlock
                    startAdornment={Icon}
                    content={<BODY1>{feature}</BODY1>}
                  />
                )}
              </Feature>
            );
          })}
        </CardContent>
        {product.button_text && product.button_link.url && (
          <Box marginTop="32px">
            <ButtonLink
              url={product.button_link.url}
              text={product.button_text}
              sliceName={`${SliceTypes.PricingTable}_${product.product_name.text}`}
              variant={product.button_variant}
              fullWidth
            />
          </Box>
        )}
      </Card>
    ));
  }, [products, remappedProductFeatures, remappedFeatures, isDesktop]);

  return (
    <div>
      <Container $verticalPadding={64} maxWidth={false}>
        {content.text && (
          <>
            <Box textAlign={isDesktop ? 'center' : 'left'}>
              <RichText
                render={content.raw}
                htmlSerializer={commonHtmlSerializer}
              />
            </Box>
            <Box height={isDesktop ? '56px' : '16px'} />
          </>
        )}
        <CardsContainer>
          {isDesktop && (
            <FeatureList>
              {remappedFeatures.map(feature => (
                <BODY1 key={feature}>{feature?.replace('&', '&\n')}</BODY1>
              ))}
            </FeatureList>
          )}
          {isMobile ? (
            <Box display="flex" flexDirection="column">
              <Tabs
                variant="fullWidth"
                indicatorColor="primary"
                textColor="primary"
                value={value}
                onChange={handleTabChange}
              >
                {products?.map(product => (
                  <Tab
                    key={product.price}
                    label={<TabLabel>{product.product_name.text}</TabLabel>}
                  />
                ))}
              </Tabs>
              <Box height="16px" />
              {cards[value]}
            </Box>
          ) : (
            cards
          )}
        </CardsContainer>
      </Container>
    </div>
  );
};
