import styled, { keyframes } from 'styled-components';
import {
  GradientOrSolidBgCss,
  PrimaryButtonColorCss,
} from '../../../utils/GlobalStyle';

export const OptionsContainer = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  ${PrimaryButtonColorCss};
  width: 100%;
  padding: 40px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: center;
  }
`;

export const Option = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 72px;
`;

export const DesktopOptionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const scrollAnimation = (count: number) => keyframes`
  to {
    transform: translateX(-${count * (32 + 72)}px);
  }
`;

export const OptionsBlock = styled.div<{ $optionsCount: number }>`
  width: 300%;
  display: flex;
  animation-name: ${({ $optionsCount }) => scrollAnimation($optionsCount)};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-fill-mode: backwards;
`;

export const MobileOptionsWrapper = styled.div`
  display: flex;
  overflow: hidden;
  ${Option} {
    flex-shrink: 0;
  }
  ${Option} + ${Option}, ${OptionsBlock} + ${OptionsBlock} {
    margin-left: 32px;
  }
`;
