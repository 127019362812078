import styled from 'styled-components';
import { Banner as BaseBanner } from '../slices/Banner';
import { CallToAction as BaseCallToAction } from '../slices/CallToAction';
import { KeyValues as BaseKeyValues } from '../slices/KeyValues';
import { AlternateTiles as BaseAlternateTiles } from '../slices/AlternateTiles';
import { Container } from '@tuunetech/tuune-components';
import { Testimonials as BaseTestimonials } from '../slices/Testimonials';
import { PersonContainer } from '../shared/Person/styles';

export const Wrapper = styled.div`
  width: 100%;
  .MuiTypography-root {
    //font-family: Mabry Pro, sans-serif;
  }
`;

export const Banner = styled(BaseBanner)`
  .MuiTypography-h1 {
    font-size: 42px;
    line-height: 63px;
  }

  .MuiTypography-h5 {
    font-size: 14px;
    letter-spacing: 1.6px;
    margin-bottom: 8px;
  }

  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const CallToAction = styled(BaseCallToAction)`
  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    text-align: center;
  }
`;

export const KeyValues = styled(BaseKeyValues)`
  max-width: 960px;
  margin: 0 auto;
  & + & {
    padding-top: 0;
  }

  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
      text-transform: none;
      font-weight: 600;
    }
  }
`;

export const AlternateTiles = styled(BaseAlternateTiles)`
  .MuiTypography-h2 {
    padding: 0 24px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .MuiTypography-h2 {
      font-size: 20px;
      line-height: 30px;
      padding: 0 16px;
    }
  }

  > ${Container} {
    padding: 0;
  }
`;

export const Testimonials = styled(BaseTestimonials)`
  padding-bottom: 0;
  ${PersonContainer} {
    .MuiTypography-root {
      font-size: 18px;
      line-height: 27px;
      width: calc(100% - 48px);
      margin: 24px auto;
      :empty {
        display: none;
      }
    }
    border-radius: 24px;
    background-color: #fed195;
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))
      drop-shadow(0 1px 10px rgba(0, 0, 0, 0.12));
    position: relative;

    ::after {
      background-color: #fed195;
      content: '';
      display: block;
      width: 48px;
      height: 48px;
      left: calc(50% - 24px);
      position: absolute;
      border-radius: 5px;
      bottom: -14px;
      z-index: -1;
      transform: rotate(45deg);
    }
  }
`;
