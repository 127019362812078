import React from 'react';
import { Slices, SliceTypes } from '../../types';
import {
  HowItWorks,
  QuoteTestimonials,
  KeyValues,
  Testimonials,
  Supported,
} from './styles';
import {
  Team,
  Options,
  Purchase,
  StepGuide,
  Banner,
  FAQ,
  KlaviyoT4CDemoForm,
  AlternateTiles,
} from 'components/slices';
import { Compare } from '../HTLanding/styles';
import { SliceWrapper } from '../shared/SliceWrapper';
import { Theme, useMediaQuery } from '@material-ui/core';

type BCLandingProps = {
  slices: Slices;
};

export const ClinicsLanding: React.FC<BCLandingProps> = ({ slices }) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.Purchase:
        return <Purchase {...slice.primary} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.FAQ:
        return <FAQ {...slice.primary} qas={slice.items} />;
      case SliceTypes.Compare:
        return <Compare {...slice.primary} statements={slice.items} />;
      case SliceTypes.StepGuide:
        return <StepGuide {...slice.primary} steps={slice.items} />;
      case SliceTypes.Supported:
        return <Supported {...slice.primary} logos={slice.items} />;
      case SliceTypes.Testimonials:
        return (
          <QuoteTestimonials {...slice.primary} testimonials={slice.items} />
        );
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.Options:
        return <Options {...slice.primary} options={slice.items} />;
      case SliceTypes.Team:
        return isDesktop ? (
          <Team teamMembers={slice.items} {...slice.primary} />
        ) : (
          <Testimonials
            stepper_color="purple"
            bg_color=""
            klaviyo_form_id=""
            testimonials={slice.items}
            content={slice.primary.title}
            {...slice.primary}
          />
        );
      case SliceTypes.KlaviyoClinicsDemoForm:
        return <KlaviyoT4CDemoForm {...slice.primary} />;
    }
  };
  return (
    <>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </>
  );
};
