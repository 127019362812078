import React from 'react';
import { SocialsItems, SocialsPrimary } from 'types';
import { Box } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { Link } from '../../shared/Link';
import { SocialsContainer, Icon } from './styles';

export const Socials: React.FC<SocialsPrimary & { links?: SocialsItems[] }> = ({
  content,
  links,
}) => {
  return (
    <SocialsContainer>
      <RichText render={content?.raw} htmlSerializer={commonHtmlSerializer} />
      <Box display="flex" marginY="40px">
        {links?.map(({ link, icon }) => {
          const iconImage = getImage(icon);
          return (
            <Icon key={icon.alt}>
              <Link to={link.url} target="_blank">
                {iconImage && <GatsbyImage alt={icon.alt} image={iconImage} />}
              </Link>
            </Icon>
          );
        })}
      </Box>
    </SocialsContainer>
  );
};
