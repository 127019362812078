import React from 'react';
import { SliceWrapper } from '../shared/SliceWrapper';
import { Slices, SliceTypes } from '../../types';
import { Banner } from '../slices/Banner';
import { Supported } from '../slices/Supported';
import { KeyValues, Text } from './styles';
import { Team } from '../slices/Team';

type AboutUsProps = {
  slices: Slices;
};

export const AboutUs: React.FC<AboutUsProps> = ({ slices }) => {
  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.Text:
        return <Text {...slice.primary} />;
      case SliceTypes.Supported:
        return <Supported {...slice.primary} logos={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.Team:
        return <Team {...slice.primary} teamMembers={slice.items} />;
    }
  };

  return (
    <>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </>
  );
};
