import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
`;

type SliceWrapperProps = {
  className?: string;
  id: string;
};
export const SliceWrapper: React.FC<SliceWrapperProps> = ({
  id,
  className,
  children,
}) => {
  return (
    <Wrapper id={id} className={className}>
      {children}
    </Wrapper>
  );
};
