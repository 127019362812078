import React from 'react';
import { KeyValuesPrimary, SliceTypes } from 'types';
import { getImage } from 'gatsby-plugin-image';
import {
  KeyValuesContainer,
  KeyValuesOuterContainer,
  Value,
  Icon,
  FooterText,
} from './styles';
import { RichText } from 'prismic-reactjs';
import {
  commonHtmlSerializer,
  commonHtmlSerializerSmallText,
} from '../commonHtmlSerializer';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { ButtonLink } from '../../shared/ButtonLink';

export const KeyValues: React.FC<KeyValuesPrimary> = ({
  items,
  content,
  icon_size,
  footer_text,
  bg_color,
  linear_gradient,
  desktop_direction,
  mobile_direction,
  className,
  button_variant,
  button_color,
  button_link,
  button_text,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <KeyValuesOuterContainer
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={desktop_direction ? 12 : 10}>
            {content?.text && (
              <Box
                textAlign={isDesktop ? 'center' : 'left'}
                marginBottom="48px"
              >
                <RichText
                  render={content.raw}
                  htmlSerializer={commonHtmlSerializer}
                />
              </Box>
            )}
            <KeyValuesContainer
              $desktopDirection={desktop_direction}
              $mobileDirection={mobile_direction}
            >
              {items?.map(({ content, icon, text_color }) => {
                const iconImage = getImage(icon);
                return (
                  <Value key={content?.text} $color={text_color}>
                    {iconImage && (
                      <Icon
                        alt={icon.alt}
                        image={iconImage}
                        $size={icon_size}
                      />
                    )}
                    <RichText
                      render={content?.raw}
                      htmlSerializer={commonHtmlSerializerSmallText}
                    />
                  </Value>
                );
              })}
            </KeyValuesContainer>
            {footer_text?.text && (
              <FooterText>
                <RichText
                  render={footer_text.raw}
                  htmlSerializer={commonHtmlSerializerSmallText}
                />
              </FooterText>
            )}
            {button_text && button_link.url && (
              <Box display="flex" justifyContent="center" marginTop="64px">
                <ButtonLink
                  url={button_link.url}
                  target={button_link.target}
                  text={button_text}
                  sliceName={SliceTypes.Testimonials}
                  color={button_color}
                  variant={button_variant}
                  fullWidth={!isDesktop}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </KeyValuesOuterContainer>
  );
};
