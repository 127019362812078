import React from 'react';
import { Container, CAPTION } from '@tuunetech/tuune-components';
import { ContentTabsPrimary, ContentTabsItems } from 'types';
import { ContentRender } from '../ContentRender';
import { Box, Theme, useMediaQuery, Tabs, Tab, Card } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { BackgroundContainer, TabContent } from './styles';
import { CONTENT_TAB_ITEM_CLICK } from '../../../utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

export const ContentTabs: React.FC<
  ContentTabsPrimary & { tabs?: ContentTabsItems[] }
> = ({ title, bg_color, linear_gradient, tabs }) => {
  const analytics = useAnalytics();
  const [value, setValue] = React.useState(0);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    analytics.track(CONTENT_TAB_ITEM_CLICK, {
      tab_title: tabs?.[newValue].title,
    });
    setValue(newValue);
  };

  return (
    <BackgroundContainer
      $bgColor={bg_color}
      $gradient={linear_gradient}
      fixed={false}
    >
      <Container $paddingTop={64} maxWidth="lg">
        <Box
          display="flex"
          flexDirection="column"
          alignItems={isDesktop ? 'center' : 'left'}
          textAlign={isDesktop ? 'center' : 'left'}
        >
          <ContentRender content={title} />
          <Box height={isDesktop ? '49px' : '24px'} />
          <Box maxWidth="552px" marginBottom="1px">
            <Tabs
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={handleChange}
            >
              {tabs?.map(({ tab_title }) => (
                <Tab key={tab_title} label={tab_title} />
              ))}
            </Tabs>
            <Box height="16px" />
            {tabs?.map(
              ({ image, title, content, content_color, disclaimer }, index) => {
                const tabImage = getImage(image);
                return (
                  value === index && (
                    <Card key={title.text}>
                      <Box
                        margin="16px"
                        minHeight="280px"
                        display="flex"
                        flexDirection="column"
                        textAlign="left"
                      >
                        <ContentRender content={title} />
                        <TabContent $color={content_color}>
                          {content.text}
                        </TabContent>
                        <CAPTION>{disclaimer.text}</CAPTION>
                        <Box marginTop="10px" alignSelf="center">
                          {tabImage && (
                            <GatsbyImage alt={image.alt} image={tabImage} />
                          )}
                        </Box>
                      </Box>
                    </Card>
                  )
                );
              },
            )}
          </Box>
        </Box>
      </Container>
    </BackgroundContainer>
  );
};
