import React, { useMemo } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SliceTypes, TestimonialsItems, TestimonialsPrimary } from 'types';
import { Person } from 'components/shared';
import {
  Container,
  Testimonials as BaseTestimonials,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { TestimonialsContainer } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';

const Wrapper: React.FC = ({ children }) => {
  return (
    <Container>
      <Box maxWidth="1060px" marginX="auto">
        {children}
      </Box>
    </Container>
  );
};

export const Testimonials: React.FC<
  TestimonialsPrimary & {
    testimonials?: TestimonialsItems[];
    className?: string;
  }
> = ({
  testimonials: originalTestimonials,
  stepper_color,
  image,
  content,
  bg_color,
  linear_gradient,
  button_text,
  button_link,
  button_color,
  button_variant,
  onClick,
  className,
}) => {
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const testimonials = useMemo(() => {
    return (
      originalTestimonials?.map(
        ({
          name,
          role,
          description,
          color,
          avatar,
          avatar: { alt },
          ...cta
        }) => {
          const image = getImage(avatar);
          return {
            color,
            id: alt,
            testimonial: (
              <Person
                key={alt}
                name={name}
                role={role}
                description={description.raw}
                image={image}
                alt={alt}
                {...cta}
              />
            ),
          };
        },
      ) || []
    );
  }, []);

  const headerImage = image ? getImage(image) : undefined;

  return (
    <TestimonialsContainer
      $gradient={linear_gradient}
      $bgColor={bg_color}
      className={className}
    >
      <Container fixed={false} $horizontalPadding={bg_color ? undefined : 0}>
        {image && headerImage && (
          <Box display="flex" justifyContent="center" marginBottom="24px">
            {headerImage && <GatsbyImage image={headerImage} alt={image.alt} />}
          </Box>
        )}
        {content?.text && (
          <Container>
            <Box textAlign="center">
              <RichText
                render={content.raw}
                htmlSerializer={commonHtmlSerializer}
              />
            </Box>
          </Container>
        )}
        <BaseTestimonials
          testimonials={testimonials}
          Wrapper={Wrapper}
          clearBg={false}
          stepperColor={stepper_color}
        />
        {button_text && button_link?.url && (
          <Container>
            <Box display="flex" justifyContent="center" width="100%">
              <ButtonLink
                url={button_link.url}
                target={button_link.target}
                text={button_text}
                sliceName={SliceTypes.Testimonials}
                color={button_color}
                variant={button_variant}
                fullWidth={!isMD}
                onClick={onClick}
              />
            </Box>
          </Container>
        )}
      </Container>
    </TestimonialsContainer>
  );
};
