import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container } from '@tuunetech/tuune-components';
import { PrimaryButtonColorCss } from '../../../utils/GlobalStyle';

export const Hero = styled(GatsbyImage)`
  width: 100%;
  max-height: 746px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 746px;
    max-height: 100%;
  }
`;

export const HeroContent = styled(Container).attrs({
  fixed: false,
  maxWidth: 'lg',
})`
  right: 0;
  position: absolute;
  bottom: 40px;
  color: #ffffff;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 81px;
  }
`;

export const PurchaseDetails = styled(Container).attrs({
  fixed: false,
  maxWidth: 'lg',
})`
  margin-left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 0 81px;
    justify-content: flex-start;
  }
`;

export const HeroContainer = styled.div`
  position: relative;
`;

export const PurchaseContainer = styled.div`
  ${PrimaryButtonColorCss};
  width: 100%;
  display: flex;
  flex-direction: column;
  ${HeroContainer}, ${PurchaseDetails} {
    width: 100%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;

    ${HeroContainer}, ${PurchaseDetails} {
      width: 50%;
    }
  }
`;
