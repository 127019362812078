import styled from 'styled-components';
import { BODY2, Divider as BaseDivider } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const FaqContainer = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  width: 100%;
  padding: 64px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 132px 0;
  }

  ul {
    padding-left: 20px;
  }

  li + li {
    margin-top: 8px;
  }
`;

export const Answer = styled(BODY2)`
  color: ${({ theme }) => theme.palette.common.grey[800]};

  & + & {
    margin-top: 8px;
  }
`;

export const Divider = styled(BaseDivider)`
  background-color: rgba(0, 0, 0, 0.14);
`;
