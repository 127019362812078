import styled from 'styled-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';
import { GatsbyImage } from 'gatsby-plugin-image';

export const PersonContainer = styled.div<{
  $gradient?: string;
  $bgColor: string;
}>`
  background-color: ${({ $bgColor }) => $bgColor};
  ${GradientOrSolidBgCss};
  width: 100%;
  padding: 64px 0;
  text-align: center;

  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: left;
  }
`;

export const Avatar = styled(GatsbyImage)`
  width: 160px;
  flex-shrink: 0;
  height: 160px;
  img {
    border-radius: 50%;
  }
  overflow: hidden;
  margin-bottom: 32px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 0;
    margin-right: 40px;
  }
`;
