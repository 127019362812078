import React, { useCallback } from 'react';
import { CAPTION } from '@tuunetech/tuune-components';
import { ContentRender } from '../ContentRender';
import { PurchasePrimary, SliceTypes } from 'types';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { HeroContainer, PurchaseContainer } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';
import { Hero, PurchaseDetails, HeroContent } from './styles';
import { useAnalytics } from 'utils/analytics';
import {
  ECOMMERCE_BIRTH_CONTROL_PRODUCT,
  ENHANCED_ECOMMERCE_EVENT,
  getProductByAppRedirectUrl,
} from 'utils/analytics/constants';

export const Purchase: React.FC<PurchasePrimary> = ({
  hero,
  hero_content,
  title,
  price,
  content,
  button_subtext,
  button_link,
  button_variant,
  button_color,
  button_text,
}) => {
  const isXXL = useMediaQuery((theme: Theme) => theme.breakpoints.up(2600));
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(768));
  const imageXl = getImage(hero);
  const imageXxl = getImage(hero.thumbnails.xxl);
  const imageLg = getImage(hero.thumbnails.lg);
  const imageMd = getImage(hero.thumbnails.md);
  const imageSm = getImage(hero.thumbnails.sm);
  const imageXs = getImage(hero.thumbnails.xs);

  const heroImage = isXXL
    ? imageXxl
    : isXL
    ? imageXl
    : isLG
    ? imageLg
    : isMD
    ? imageMd
    : isSM
    ? imageSm
    : imageXs;

  const analytics = useAnalytics();

  const onButtonLinkClick = useCallback(
    (redirectUrl: string) => {
      const ecommerceProduct = getProductByAppRedirectUrl(redirectUrl);
      if (ecommerceProduct) {
        analytics.logEnhancedEcommerceEvent(
          ENHANCED_ECOMMERCE_EVENT.ADD_TO_CART,
          ECOMMERCE_BIRTH_CONTROL_PRODUCT,
        );
      }
      window.location.href = redirectUrl;
    },
    [analytics],
  );

  return (
    <PurchaseContainer>
      <HeroContainer>
        {heroImage && <Hero alt="banner" image={heroImage} />}
        <HeroContent>
          <ContentRender content={hero_content} />
        </HeroContent>
      </HeroContainer>
      <PurchaseDetails>
        <Box display="flex" flexDirection="column" marginY={!isMD ? '64px' : 0}>
          <ContentRender content={title} />
          <Box height={isMD ? '16px' : '8px'} />
          <ContentRender content={price} />
          <Box height="16px" />
          <ContentRender content={content} />
          {button_text && button_link?.url && (
            <>
              <Box height="32px" />
              <ButtonLink
                url={button_link.url}
                target={button_link.target}
                text={button_text}
                sliceName={SliceTypes.Purchase}
                variant={button_variant}
                color={button_color}
                fullWidth={!isMD}
                onClick={() => onButtonLinkClick(button_link.url)}
              />
            </>
          )}
          <Box marginTop="16px" textAlign={isMD ? 'left' : 'center'}>
            <CAPTION>{button_subtext?.text}</CAPTION>
          </Box>
        </Box>
      </PurchaseDetails>
    </PurchaseContainer>
  );
};
