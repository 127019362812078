import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const KeyValuesOuterContainer = styled.div<{
  $gradient?: string;
  $bgColor: string;
}>`
  padding: 64px 0;
  background-color: ${({ $bgColor }) => $bgColor};
  ${GradientOrSolidBgCss};
  width: 100%;
`;

export const Value = styled.div<{ $color?: string }>`
  display: flex;
  align-items: center;
  color: ${({ $color, theme }) => $color || theme.palette.common.black};
`;

export const Icon = styled(GatsbyImage)<{ $size?: number }>`
  width: ${({ $size }) => $size || 40}px;
  min-width: ${({ $size }) => $size || 40}px;
  height: ${({ $size }) => $size || 40}px;
`;

export const KeyValuesContainer = styled.div<{
  $desktopDirection: boolean; // true - row, false - column
  $mobileDirection: boolean; // true - row, false - column
}>`
  display: flex;
  justify-content: center;
  flex-wrap: ${({ $mobileDirection }) =>
    $mobileDirection ? 'nowrap' : 'wrap'};

  ${Icon} {
    margin-bottom: ${({ $mobileDirection }) => ($mobileDirection ? 12 : 0)}px;
    margin-right: ${({ $mobileDirection }) => ($mobileDirection ? 0 : 12)}px;
  }

  ${Value} {
    width: ${({ $mobileDirection }) => ($mobileDirection ? '30%' : '100%')};
    flex-direction: ${({ $mobileDirection }) =>
      $mobileDirection ? 'column' : 'row'};
    text-align: ${({ $mobileDirection }) =>
      $mobileDirection ? 'center' : 'left'};
    margin: ${({ $mobileDirection }) =>
      $mobileDirection ? '0 15px' : '0 0 24px 0'};
    :last-of-type {
      margin-bottom: 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-wrap: ${({ $desktopDirection }) =>
      $desktopDirection ? 'nowrap' : 'wrap'};

    ${Icon} {
      margin-bottom: ${({ $desktopDirection }) =>
        $desktopDirection ? 12 : 0}px;
      margin-right: ${({ $desktopDirection }) =>
        $desktopDirection ? 0 : 12}px;
    }

    ${Value} {
      width: ${({ $desktopDirection }) => ($desktopDirection ? '30%' : '100%')};
      flex-direction: ${({ $desktopDirection }) =>
        $desktopDirection ? 'column' : 'row'};
      text-align: ${({ $desktopDirection }) =>
        $desktopDirection ? 'center' : 'left'};
      margin: ${({ $desktopDirection }) =>
        $desktopDirection ? '0 15px' : '0 0 24px 0'};
    }
  }
`;

export const FooterText = styled.div`
  margin-top: 64px;
  color: ${({ theme }) => theme.palette.common.grey[700]};
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: center;
  }
`;
