import styled, { css } from 'styled-components';
import { HowItWorksStack } from '../slices/HowItWorks/HowItWorksStack';
import { Avatar } from '../slices/HowItWorks/styles';
import { Supported as BaseSupported } from '../slices/Supported';
import { QuoteTestimonials } from '../slices/Testimonials/QuoteTestimonials';
import { PersonContainer } from '../shared/Person/styles';
import { AlternateTiles as BaseAlternateTiles } from '../slices/AlternateTiles';
import { Item, Tile, TileImage } from '../slices/AlternateTiles/styles';
import { BODY1 } from '@tuunetech/tuune-components';

const ButtonColorCss = css`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
      color: #ffffff;
    }
  }

  .MuiButton-textPrimary {
    color: #613ac7;
  }
`;

export const HowItWorks = styled(HowItWorksStack)`
  ${Avatar} {
    width: 100px;
    height: 100px;
    border-radius: 0;
  }
`;

export const Supported = styled(BaseSupported)`
  margin: 0;
`;

export const Testimonials = styled(QuoteTestimonials)`
  ${ButtonColorCss};
  ${PersonContainer} {
    margin-top: -40px;
  }
`;

export const AlternateTiles = styled(BaseAlternateTiles)`
  ${TileImage} {
    border-radius: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${Tile} {
      flex-direction: column;
    }

    ${Item} {
      width: 100%;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${TileImage} {
      height: 245px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('xl')} {
    ${TileImage} {
      height: 170px;
    }
  }
`;

export const HiwAlternateTiles = styled(BaseAlternateTiles)`
  ${BODY1} + img {
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: bottom;
    margin-top: 8px;
  }

  ${BODY1}:first-of-type {
    margin: 24px 0;
  }

  img + ${BODY1} {
    margin-left: 8px;
    display: inline-flex;
    width: calc(100% - 48px);
    height: 40px;
    align-items: center;
  }

  ${TileImage} {
    max-width: 318px;
  }

  ${Item} {
    width: fit-content;
  }
`;
