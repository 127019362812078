import React from 'react';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { CTAPrimary, SliceTypes } from 'types';
import { TitleParagraphsGroup } from 'components/shared';
import { CTAContainer, ImageWrapper } from './styles';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { ButtonLink } from '../../shared/ButtonLink';

export const CallToAction: React.FC<CTAPrimary & { className?: string }> = ({
  image,
  title,
  paragraphs,
  bg_color,
  button_link,
  button_variant,
  button_color,
  button_text,
  linear_gradient,
  remove_top_margin,
  onClick,
  className,
}) => {
  const isOnlyTitle =
    !!title.text && !paragraphs.text && !button_link.url && !button_text;

  const mainImage = image ? getImage(image) : undefined;

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs'),
  );

  return (
    <CTAContainer
      $bgColor={bg_color}
      $gradient={linear_gradient}
      fixed={false}
      $removeTopMargin={remove_top_margin}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <Box display="flex" flexDirection="column">
              {image && (
                <ImageWrapper>
                  {mainImage && (
                    <GatsbyImage image={mainImage} alt={image.alt} />
                  )}
                </ImageWrapper>
              )}
              <TitleParagraphsGroup
                title={title.raw}
                paragraphs={paragraphs.raw}
                smallTitle={isOnlyTitle}
              />
              {button_link.url && button_text && (
                <Box display="flex" justifyContent="center" marginTop="32px">
                  <ButtonLink
                    url={button_link.url}
                    target={button_link.target}
                    text={button_text}
                    variant={button_variant}
                    color={button_color}
                    sliceName={SliceTypes.CTA}
                    onClick={onClick}
                    fullWidth={isMobile}
                  />
                </Box>
              )}
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </CTAContainer>
  );
};
