import React, { ReactNode } from 'react';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import { BODY1, H1, H2, H3, H4, H5 } from '@tuunetech/tuune-components';
import { ImageWrap } from './styles';

export const htmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
      return (
        <BODY1 key={key} display="inline">
          {children}
        </BODY1>
      );
    case Elements.heading1:
      return <H1 key={key}>{children}</H1>;
    case Elements.heading2:
      return <H2 key={key}>{children}</H2>;
    case Elements.heading3:
      return <H3 key={key}>{children}</H3>;
    case Elements.heading4:
      return <H4 key={key}>{children}</H4>;
    case Elements.heading5:
      return <H5 key={key}>{children}</H5>;
    case Elements.image:
      return (
        <ImageWrap>
          <img src={element.url} />
        </ImageWrap>
      );
  }
};
