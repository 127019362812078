import React, { useEffect } from 'react';
import { Slices, SliceTypes } from '../../types';
import {
  AlternateTiles,
  Banner,
  CallToAction,
  Compare,
  CustomTestimonials,
  HowItWorks,
  Influencer,
  Promo,
  StepGuide,
  Testimonials,
} from './styles';
import { FAQ } from '../slices/FAQ';
import { SliceWrapper } from '../shared/SliceWrapper';
import { Purchase } from '../slices/Purchase';
import {
  ECOMMERCE_HORMONE_TEST_PRODUCT,
  ENHANCED_ECOMMERCE_EVENT,
} from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

type HTLandingProps = {
  slices: Slices;
};

export const HTLanding: React.FC<HTLandingProps> = ({ slices }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.logEnhancedEcommerceEvent(
      ENHANCED_ECOMMERCE_EVENT.VIEW_ITEM,
      ECOMMERCE_HORMONE_TEST_PRODUCT,
    );
  }, [analytics]);

  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.Promo:
        return <Promo {...slice.primary} />;
      case SliceTypes.FAQ:
        return <FAQ {...slice.primary} qas={slice.items} />;
      case SliceTypes.StepGuide:
        return <StepGuide {...slice.primary} steps={slice.items} />;
      case SliceTypes.Testimonials:
        const testimonial = slice.items ? slice.items[0] : undefined;
        return testimonial?.name ? (
          <Testimonials {...slice.primary} testimonials={slice.items} />
        ) : (
          <CustomTestimonials {...slice.primary} testimonials={slice.items} />
        );
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.Compare:
        return <Compare {...slice.primary} statements={slice.items} />;
      case SliceTypes.CTA:
        return <CallToAction {...slice.primary} />;
      case SliceTypes.Influencer:
        return <Influencer {...slice.primary} />;
      case SliceTypes.Purchase:
        return <Purchase {...slice.primary} />;
    }
  };

  return (
    <>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </>
  );
};
