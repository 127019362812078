import React from 'react';
import { SliceTypes, VideoPrimary } from 'types';
import { RichText } from 'prismic-reactjs';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box } from '@material-ui/core';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { VideoFrame, VideoWrapper } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';

export const Video: React.FC<VideoPrimary> = ({
  video,
  content,
  button_color,
  button_variant,
  button_link,
  button_text,
}) => {
  return (
    <Container $verticalMargin={40} maxWidth="lg">
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={10} xl={12}>
          <RichText
            render={content?.raw}
            htmlSerializer={commonHtmlSerializer}
          />
          <Box height="32px" />
          <VideoWrapper>
            <VideoFrame url={video.embed_url} />
          </VideoWrapper>
          {button_link?.url && button_text && (
            <Box display="flex" justifyContent="center" marginTop="32px">
              <ButtonLink
                url={button_link.url}
                target={button_link.target}
                text={button_text}
                sliceName={SliceTypes.Video}
                color={button_color}
                variant={button_variant}
              />
            </Box>
          )}
        </GridItem>
      </GridContainer>
    </Container>
  );
};
