import React from 'react';
import { SliceTypes, WiwItems, WiwPrimary } from 'types';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { ContentRender } from '../ContentRender';
import { htmlSerializer } from './htmlSerializer';
import { Item, List, WiwWrapper } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';

export const WhyItWorks: React.FC<
  WiwPrimary & { items?: WiwItems[]; className?: string }
> = ({
  items,
  button_link,
  button_variant,
  button_color,
  button_text,
  button_link_desk,
  title,
  className,
  linear_gradient,
  bg_color,
}) => {
  const isXL = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(414));

  return (
    <WiwWrapper
      className={className}
      $bgColor={bg_color}
      $gradient={linear_gradient}
    >
      <Container $verticalMargin={40} maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <ContentRender content={title} />
            <Box height="16px" />
            {items?.map(({ title, image, content }) => {
              const sizeImage = getImage(
                isXL
                  ? image
                  : isLG
                  ? image.thumbnails.lg
                  : isMD
                  ? image.thumbnails.md
                  : isSM
                  ? image.thumbnails.sm
                  : image.thumbnails.xs,
              );
              return (
                <Item key={image.alt}>
                  <div>
                    <ContentRender content={title} />
                    <List>
                      <RichText
                        render={content?.raw}
                        htmlSerializer={htmlSerializer}
                      />
                    </List>
                  </div>
                  <Box marginY="24px">
                    {sizeImage && (
                      <GatsbyImage alt={image.alt} image={sizeImage} />
                    )}
                  </Box>
                </Item>
              );
            })}
            {button_text && button_link?.url && button_link_desk?.url && (
              <Box
                marginTop="48px"
                marginX="auto"
                width={isMD ? 'fit-content' : 'auto'}
              >
                <ButtonLink
                  url={isMD ? button_link_desk.url : button_link.url}
                  target="_blank"
                  text={button_text}
                  sliceName={SliceTypes.WIW}
                  variant={button_variant}
                  color={button_color}
                  fullWidth={!isMD}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </WiwWrapper>
  );
};
