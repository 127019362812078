import styled from 'styled-components';
import { Person } from 'components/shared';
import { Avatar } from 'components/shared/Person/styles';
import { Button } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from 'utils/GlobalStyle';

export const Wrapper = styled.div<{ $bgColor: string; $gradient: string }>`
  width: 100%;
  ${GradientOrSolidBgCss};
`;

export const TeamContainer = styled.div`
  padding: 64px 0;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 106px 0;
  }

  ${Button} {
    margin-top: 64px;
  }
`;

export const Member = styled(Person)`
  width: 262px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    width: 45%;
  }

  ${Avatar} {
    width: 160px;
    height: 160px;

    ${({ theme }) => theme.breakpoints.up('lg')} {
      align-self: self-start;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    max-width: 440px;
    text-align: left;
  }
`;

export const Members = styled.div`
  width: 100%;
  margin-top: 36px;
  display: flex;
  column-gap: 16px;
  row-gap: 24px;
  flex-wrap: wrap;
  justify-content: space-evenly;

  ${({ theme }) => theme.breakpoints.up('lg')} {
    row-gap: 64px;
    margin-top: 64px;
  }
`;
