import React from 'react';
import {
  BODY2,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { CompareWrapper, Row, StatementCol, ValueCol } from './styles';
import { CompareItems, ComparePrimary, SliceTypes } from '../../../types';
import { ContentRender } from '../ContentRender';
import { RichText } from 'prismic-reactjs';
import { commonHtmlSerializerSmallText } from '../commonHtmlSerializer';
import { Cancel, CheckCircle } from '@material-ui/icons';
import { ButtonLink } from '../../shared/ButtonLink';
import { Box, Theme, useMediaQuery } from '@material-ui/core';

export const Compare: React.FC<
  ComparePrimary & { statements?: CompareItems[]; className?: string }
> = ({
  bg_color,
  linear_gradient,
  content,
  statements,
  option_1_name,
  option_2_name,
  className,

  button_text,
  button_link,
  button_color,
  button_variant,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <CompareWrapper
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <ContentRender content={content} />
            <Row>
              <StatementCol />
              <ValueCol>
                <BODY2>{option_1_name}</BODY2>
              </ValueCol>
              <ValueCol>
                <BODY2>{option_2_name}</BODY2>
              </ValueCol>
            </Row>
            {statements?.map(
              ({ compare_statement, option_1_value, option_2_value }) => (
                <Row key={compare_statement.text}>
                  <StatementCol>
                    <RichText
                      render={compare_statement.raw}
                      htmlSerializer={commonHtmlSerializerSmallText}
                    />
                  </StatementCol>
                  <ValueCol $value={option_1_value}>
                    {option_1_value ? <CheckCircle /> : <Cancel />}
                  </ValueCol>
                  <ValueCol $value={option_2_value}>
                    {option_2_value ? <CheckCircle /> : <Cancel />}
                  </ValueCol>
                </Row>
              ),
            )}
            {button_text && button_link.url && (
              <Box marginTop="32px" display="flex" justifyContent="center">
                <ButtonLink
                  text={button_text}
                  url={button_link.url}
                  target={button_link.target}
                  sliceName={SliceTypes.Compare}
                  variant={button_variant}
                  fullWidth={!isDesktop}
                  color={button_color}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </CompareWrapper>
  );
};
