import { RichTextBlock } from 'prismic-reactjs';
import { ImageDataLike } from 'gatsby-plugin-image';
import { AppHeaderProps } from '@tuunetech/tuune-components';
import { CommonColors } from '@material-ui/core/styles/createPalette';

export enum SliceTypes {
  Banner = 'banner',
  CTA = 'call_to_action',
  Supported = 'support',
  AlternateTiles = 'alternate_tiles',
  Team = 'team',
  Testimonials = 'testimonials',
  FAQ = 'faq',
  Symptoms = 'symptoms',
  Promo = 'promo',
  Socials = 'socials',
  KeyValues = 'key_values',
  Video = 'video',
  Options = 'options',
  Text = 'text',
  WIW = 'why_it_works',
  Purchase = 'purchase',
  ContentTabs = 'content_tabs',
  HIW = 'how_it_works',
  Person = 'person',
  StepGuide = 'step_guide',
  CMPFrame = 'cmp_frame',
  Compare = 'compare',
  Influencer = 'influencer',
  PricingTable = 'pricing_table',
  KlaviyoClinicsDemoForm = 'klaviyo_clinics_demo_form',
}

// util type
export type PrismicField = {
  html: string;
  raw: RichTextBlock[];
  text: string;
  text_color?: string;
};

export type CTAField = {
  button_text: string;
  button_color: 'primary' | 'white' | 'grey' | 'yellow' | 'green' | 'red';
  button_variant: 'text' | 'outlined' | 'contained';
  button_link: LinkField;
  onClick?: () => void;
};

type ImageField = ImageDataLike & {
  alt: string;
};
type ImageBreakpointThumbnails = {
  xs: ImageDataLike;
  sm: ImageDataLike;
  md: ImageDataLike;
  lg: ImageDataLike;
  xxl: ImageDataLike;
};

type LinearGradientField = {
  linear_gradient?: string;
};

export type LinkField = {
  url: string;
  target?: string;
};

type Slice<T, P, I = unknown> = {
  id: string;
  slice_type: T;
  primary: P & {
    // required field for anchor links
    id: string;
  };
  items?: I[];
};

// BANNER
export type BannerPrimary = {
  title: PrismicField;
  content: PrismicField;
  text_color: string;
  hero: ImageField & { thumbnails: ImageBreakpointThumbnails };
  klaviyo_form_id: string;
} & CTAField;

type BannerSlice = Slice<SliceTypes.Banner, BannerPrimary>;

// CALL TO ACTION
export type CTAPrimary = {
  image?: ImageField;
  title: PrismicField;
  paragraphs: PrismicField;
  bg_color: string;
  remove_top_margin: boolean;
  klaviyo_form_id: string;
} & CTAField &
  LinearGradientField;

type CTASlice = Slice<SliceTypes.CTA, CTAPrimary>;

// SUPPORTED
export type SupportedPrimary = {
  content: PrismicField;
  text_color: string;
  bg_color: string;
} & CTAField &
  LinearGradientField;
export type SupportedItems = {
  link: LinkField;
  logo: ImageField & {
    thumbnails: Pick<ImageBreakpointThumbnails, 'xs'>;
  };
};

type SupportedSlice = Slice<
  SliceTypes.Supported,
  SupportedPrimary,
  SupportedItems
>;

// ALTERNATE TILES
export type AlternateTilesPrimary = {
  bg_color: string;
  image_first: boolean;
  mobile_image_first: boolean;
  content: PrismicField;
} & CTAField &
  LinearGradientField;
export type AlternateTilesItems = {
  content: PrismicField;
  image: ImageField & {
    thumbnails: Omit<ImageBreakpointThumbnails, 'xl'>;
  };
} & CTAField;

type AlternateTilesSlice = Slice<
  SliceTypes.AlternateTiles,
  AlternateTilesPrimary,
  AlternateTilesItems
>;

// TEAM
export type TeamPrimary = {
  title: PrismicField;
  paragraphs: PrismicField;
  is_scrollable: boolean;
  bg_color: string;
} & CTAField &
  LinearGradientField;
export type TeamItems = {
  name: string;
  role: string;
  description: PrismicField;
  avatar: ImageField;
};

type TeamSlice = Slice<SliceTypes.Team, TeamPrimary, TeamItems>;

// TESTIMONIALS
export type TestimonialsPrimary = {
  image?: ImageField;
  content?: PrismicField;
  stepper_color: keyof CommonColors;
  bg_color: string;
  klaviyo_form_id: string;
} & CTAField &
  LinearGradientField;
export type TestimonialsItems = TeamItems &
  CTAField & {
    color: string;
  };
type TestimonialsSlice = Slice<
  SliceTypes.Testimonials,
  TestimonialsPrimary,
  TestimonialsItems
>;

// FAQ
export type FaqPrimary = {
  bg_color: string;
  title: PrismicField;
  paragraphs?: PrismicField;
} & CTAField &
  LinearGradientField;
export type FaqItems = {
  q: PrismicField;
  a: PrismicField;
};

type FaqSlice = Slice<SliceTypes.FAQ, FaqPrimary, FaqItems>;

// SYMPTOMS
export type SymptomsPrimary = {
  title: PrismicField;
};
export type SymptomsItems = {
  image: ImageField & {
    thumbnails: Pick<ImageBreakpointThumbnails, 'xs'>;
  };
  symptom_name: PrismicField;
};

type SymptomsSlice = Slice<SliceTypes.Symptoms, SymptomsPrimary, SymptomsItems>;

// PROMO
export type PromoPrimary = {
  image: ImageField & {
    thumbnails: Omit<ImageBreakpointThumbnails, 'xl'>;
  };
  content_position: boolean;
  content: PrismicField;
  content_color: string;
  text_align: string;
  bg_color: string;
} & CTAField &
  LinearGradientField;

type PromoSlice = Slice<SliceTypes.Promo, PromoPrimary>;

// SOCIALS
export type SocialsPrimary = {
  content: PrismicField;
};

export type SocialsItems = {
  icon: ImageField;
  link: LinkField;
};

type SocialsSlice = Slice<SliceTypes.Socials, SocialsPrimary, SocialsItems>;

// KEY VALUES
export type KeyValuesPrimary = {
  content?: PrismicField;
  mobile_direction: boolean; // true - row, false - column
  desktop_direction: boolean; // true - row, false - column
  bg_color: string;
  icon_size: number;
  footer_text: PrismicField;
} & LinearGradientField &
  CTAField;
export type KeyValuesItems = {
  icon: ImageField;
  content: PrismicField;
  text_color: string;
};

type KeyValuesSlice = Slice<SliceTypes.KeyValues, unknown, KeyValuesItems>;

// VIDEO
export type VideoPrimary = {
  video: {
    embed_url: string;
  };
  content: PrismicField;
} & CTAField;

type VideoSlice = Slice<SliceTypes.Video, VideoPrimary>;

// OPTIONS
export type OptionsPrimary = {
  content: PrismicField;
  bg_color: string;
} & CTAField &
  LinearGradientField;
export type OptionsItems = KeyValuesItems;
type OptionsSlice = Slice<SliceTypes.Options, OptionsPrimary, OptionsItems>;

// TEXT
export type TextPrimary = {
  content: PrismicField;
  bg_color: string;
} & LinearGradientField;
type TextSlice = Slice<SliceTypes.Text, TextPrimary>;

// WIW
export type WiwPrimary = {
  title: PrismicField;
  button_link_desk: LinkField;
  bg_color: string;
} & CTAField &
  LinearGradientField;
export type WiwItems = {
  title: PrismicField;
  image: ImageField & {
    thumbnails: Omit<ImageBreakpointThumbnails, 'xl'>;
  };
  content: PrismicField;
};

type WiwSlice = Slice<SliceTypes.WIW, WiwPrimary, WiwItems>;

// PURCHASE
export type PurchasePrimary = {
  title: PrismicField;
  price: PrismicField;
  content: PrismicField;
  button_subtext: PrismicField;
  hero: ImageField & { thumbnails: ImageBreakpointThumbnails };
  hero_content: PrismicField;
} & CTAField;

type PurchaseSlice = Slice<SliceTypes.Purchase, PurchasePrimary, unknown>;

// CONTENT TABS
export type ContentTabsPrimary = {
  title: PrismicField;
  bg_color: string;
} & LinearGradientField;
export type ContentTabsItems = {
  image: ImageField;
  tab_title: string;
  title: PrismicField;
  content: PrismicField;
  content_color: string;
  disclaimer: PrismicField;
};

type ContentTabsSlice = Slice<
  SliceTypes.ContentTabs,
  ContentTabsPrimary,
  ContentTabsItems
>;

// HIW
export type HiwPrimary = {
  title: PrismicField;
  bg_color: string;
  klaviyo_form_id: string;
} & CTAField &
  LinearGradientField;
export type HiwItems = {
  image: ImageField;
  description: PrismicField;
};

type HiwSlice = Slice<SliceTypes.HIW, HiwPrimary, HiwItems>;

// Person
export type PersonPrimary = {
  content: PrismicField;
  avatar: ImageField;
  summary: PrismicField;
  quote: PrismicField;
  bg_color: string;
} & LinearGradientField;

type PersonSlice = Slice<SliceTypes.Person, PersonPrimary>;

// Step Guide
export type StepGuidePrimary = {
  content: PrismicField;
  bg_color: string;
} & CTAField;
export type StepGuideItems = {
  content: PrismicField;
};

type StepGuideSlice = Slice<
  SliceTypes.StepGuide,
  StepGuidePrimary,
  StepGuideItems
>;

// CMP frame
type CMPFrameSlice = Slice<SliceTypes.CMPFrame, unknown>;

// Compare
export type ComparePrimary = {
  content: PrismicField;
  button_color: string;
  option_1_name: string;
  option_2_name: string;
  bg_color: string;
} & CTAField &
  LinearGradientField;

export type CompareItems = {
  compare_statement: PrismicField;
  option_1_value: boolean;
  option_2_value: boolean;
};

type CompareSlice = Slice<SliceTypes.Compare, ComparePrimary, CompareItems>;

// INFLUENCER
export type InfluencerPrimary = {
  quote: PrismicField;
  account: string;
  avatar: ImageField;
  complex_button_text: PrismicField;
  bg_color: string;
} & Omit<CTAField, 'button_text'> &
  LinearGradientField;

type InfluencerSlice = Slice<SliceTypes.Influencer, InfluencerPrimary>;

// Pricing Table
export type PricingTablePrimary = {
  content: PrismicField;
  features: PrismicField;
};

export type PricingTableItems = {
  product_name: PrismicField;
  price: number;
  best: boolean;
  features: PrismicField;
} & CTAField;

type PricingTableSlice = Slice<
  SliceTypes.PricingTable,
  PricingTablePrimary,
  PricingTableItems
>;

// Klaviyo Clincis Demo Form
export type KlaviyoT4CDemoFormPrimary = {
  id: string;
  bg_color: PrismicField;
};

type KlaviyoClinicsDemoFormSlice = Slice<
  SliceTypes.KlaviyoClinicsDemoForm,
  KlaviyoT4CDemoFormPrimary
>;

// static page data
type StaticZone = {
  app_bar_color: AppHeaderProps['color'];
  links: {
    text: string;
    url: LinkField;
  }[];
  promo_bg: string | null;
  promo_color: string | null;
  promo_content: PrismicField;
};

export type Slices = (
  | BannerSlice
  | CTASlice
  | SupportedSlice
  | AlternateTilesSlice
  | TeamSlice
  | TestimonialsSlice
  | FaqSlice
  | SymptomsSlice
  | PromoSlice
  | SocialsSlice
  | KeyValuesSlice
  | VideoSlice
  | OptionsSlice
  | TextSlice
  | WiwSlice
  | PurchaseSlice
  | ContentTabsSlice
  | HiwSlice
  | PersonSlice
  | StepGuideSlice
  | CMPFrameSlice
  | CompareSlice
  | InfluencerSlice
  | PricingTableSlice
  | KlaviyoClinicsDemoFormSlice
)[];

// slices
type DynamicZone = {
  body: Slices;
};

export type PageData = StaticZone & DynamicZone;
