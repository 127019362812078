import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { H1 } from '@tuunetech/tuune-components';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout } from 'components/shared/Layout';
import { PageData, SliceTypes } from 'types';
import {
  AlternateTiles,
  Banner,
  CallToAction,
  ContentTabs,
  FAQ,
  HowItWorks,
  KeyValues,
  Options,
  Promo,
  Purchase,
  Socials,
  Supported,
  Symptoms,
  Team,
  Testimonials,
  Text,
  Video,
  WhyItWorks,
  CMPFrame,
} from 'components/slices';
import { linkResolver } from 'utils/linkResolver';
import { Person } from '../components/slices/Person';
import { StepGuide } from '../components/slices/StepGuide';
import { PAGE_VIEW } from '../utils/analytics/constants';
import { SupportProgram } from '../components/SupportProgram';
import { CycleTracking } from '../components/CycleTracking';
import { HTLanding } from '../components/HTLanding';
import { BCLanding } from '../components/BCLanding';
import { SliceWrapper } from '../components/shared/SliceWrapper';
import { HolisticVP } from '../components/HolisticVP';
import { HormonalWellbeing } from '../components/HormonalWellbeing';
import { ClinicsLanding } from '../components/ClinicsLanding';
import { useAnalytics } from 'utils/analytics';
import { AboutUs } from '../components/AboutUs';

export type PageTemplateProps = {
  prismicPage: {
    uid: string;
    data: PageData;
  };
};

const Page: React.FC<PageProps<PageTemplateProps>> = ({ location, data }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.track(PAGE_VIEW, {
      pageUrl: location.pathname,
    });
  }, []);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.querySelector(location.hash);

        if (!element) {
          window.scrollTo(0, 0);
        } else {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 300);
    }
  }, []);

  const renderDefaultSlice = (
    slice: typeof data.prismicPage.data.body[number],
  ) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.CTA:
        return <CallToAction {...slice.primary} />;
      case SliceTypes.Supported:
        return <Supported {...slice.primary} logos={slice.items} />;
      case SliceTypes.AlternateTiles:
        return <AlternateTiles {...slice.primary} tiles={slice.items} />;
      case SliceTypes.Team:
        return <Team {...slice.primary} teamMembers={slice.items} />;
      case SliceTypes.Testimonials:
        return <Testimonials {...slice.primary} testimonials={slice.items} />;
      case SliceTypes.FAQ:
        return <FAQ {...slice.primary} qas={slice.items} />;
      case SliceTypes.Symptoms:
        return <Symptoms {...slice.primary} symptoms={slice.items} />;
      case SliceTypes.Promo:
        return <Promo {...slice.primary} />;
      case SliceTypes.Socials:
        return <Socials {...slice.primary} links={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.Video:
        return <Video {...slice.primary} />;
      case SliceTypes.Options:
        return <Options {...slice.primary} options={slice.items} />;
      case SliceTypes.Text:
        return <Text {...slice.primary} />;
      case SliceTypes.WIW:
        return <WhyItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.Person:
        return <Person {...slice.primary} />;
      case SliceTypes.StepGuide:
        return <StepGuide {...slice.primary} steps={slice.items} />;
      case SliceTypes.Purchase:
        return <Purchase {...slice.primary} items={slice.items} />;
      case SliceTypes.ContentTabs:
        return <ContentTabs {...slice.primary} tabs={slice.items} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.CMPFrame:
        return <CMPFrame />;
      default:
        return <H1 color="error">Unknown slice</H1>;
    }
  };

  const renderPageTemplate = () => {
    switch (data.prismicPage.uid) {
      case 'support-program':
        return <SupportProgram slices={data.prismicPage.data.body} />;
      case 'cycle-tracking':
        return <CycleTracking slices={data.prismicPage.data.body} />;
      case 'hormone-test':
        return <HTLanding slices={data.prismicPage.data.body} />;
      case 'birth-control':
        return <BCLanding slices={data.prismicPage.data.body} />;
      case 'clinics':
        return <ClinicsLanding slices={data.prismicPage.data.body} />;
      case 'hormonal-wellbeing':
        return <HormonalWellbeing slices={data.prismicPage.data.body} />;
      case 'homepage':
        return <HolisticVP slices={data.prismicPage.data.body} />;
      case 'about-us':
        return <AboutUs slices={data.prismicPage.data.body} />;
      default:
        return data.prismicPage.data.body.map(slice => (
          <SliceWrapper key={slice.id} id={slice.primary.id}>
            {renderDefaultSlice(slice)}
          </SliceWrapper>
        ));
    }
  };

  return (
    <Layout
      barColor={data.prismicPage.data.app_bar_color}
      links={data.prismicPage.data.links}
      promo={
        data.prismicPage.data.promo_content?.text
          ? {
              content: data.prismicPage.data.promo_content,
              bg: data.prismicPage.data.promo_bg,
              color: data.prismicPage.data.promo_color,
            }
          : null
      }
    >
      {renderPageTemplate()}
    </Layout>
  );
};

export const query = graphql`
  query($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      uid
      data {
        app_bar_color
        links {
          text
          url {
            url
          }
        }
        promo_bg
        promo_color
        promo_content {
          raw
          text
        }
        body {
          ... on PrismicSliceType {
            slice_type
            id
          }
          ...PageDataBodyBanner
          ...PageDataBodyCallToAction
          ...PageDataBodySupport
          ...PageDataBodyAlternateTiles
          ...PageDataBodyTeam
          ...PageDataBodyTestimonials
          ...PageDataBodyFaq
          ...PageDataBodySymptoms
          ...PageDataBodyPromo
          ...PageDataBodySocials
          ...PageDataBodyKeyValues
          ...PageDataBodyVideo
          ...PageDataBodyOptions
          ...PageDataBodyText
          ...PageDataBodyWhyItWorks
          ...PageDataBodyPerson
          ...PageDataBodyStepGuide
          ...PageDataBodyPurchase
          ...PageDataBodyContentTabs
          ...PageDataBodyHowItWorks
          ...PageDataBodyCmpFrame
          ...PageDataBodyCompare
          ...PageDataBodyInfluencer
          ...PageDataBodyPricingTable
          ...PageDataBodyKlaviyoClinicsDemoForm
        }
      }
    }
  }
`;

export default withPrismicPreview(Page, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME || '',
    linkResolver,
  },
]);
