import React, { useCallback } from 'react';
import { Button, ButtonProps } from '@tuunetech/tuune-components';
import { useLocation } from '@reach/router';
import { Link } from '../Link';
import { CTA_CLICK } from '../../../utils/analytics/constants';
import { SliceTypes } from '../../../types';
import { getUrl } from '../../../utils/helpers';
import { useAnalytics } from 'utils/analytics';

type ButtonLinkProps = ButtonProps & {
  url: string;
  target?: string;
  text: string;
  sliceName: SliceTypes;
};
export const ButtonLink: React.FC<ButtonLinkProps> = ({
  url,
  target,
  text,
  sliceName,
  onClick,
  ...buttonProps
}) => {
  const location = useLocation();
  const analytics = useAnalytics();

  const handleClick = useCallback(() => {
    analytics.track(CTA_CLICK, {
      ctaText: text,
      pageUrlFrom: location.pathname,
      pageUrlTo: getUrl(url),
      sliceName,
    });
  }, [text, location.pathname, sliceName, url, analytics]);

  const handleLinkClick = useCallback(
    e => {
      if (onClick) {
        e.preventDefault();
        onClick(e);
      }
    },
    [onClick],
  );

  return (
    <Link
      to={getUrl(url)}
      target={target}
      underline="none"
      style={{ width: buttonProps.fullWidth ? '100%' : 'fit-content' }}
      onClick={handleLinkClick}
    >
      <Button onClick={handleClick} {...buttonProps}>
        {text}
      </Button>
    </Link>
  );
};
