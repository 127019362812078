import React from 'react';
import { Elements, HTMLSerializer } from 'prismic-reactjs';
import { ReactNode } from 'react';
import { Answer } from './styles';
import { Link } from 'components/shared';
import { getUrl } from 'utils/helpers';

export const htmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  content,
  children,
  key,
) => {
  switch (type) {
    case Elements.paragraph:
      return <Answer key={key}>{children}</Answer>;
    case Elements.listItem:
      return (
        <li key={key}>
          <Answer>{children}</Answer>
        </li>
      );
    case Elements.hyperlink:
      // for external links
      // for internal links use linkResolver
      return (
        <Link
          key={key}
          component="span"
          to={getUrl(element.data.url)}
          target={element.data.target}
        >
          {children}
        </Link>
      );
  }
};
