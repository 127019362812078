import styled from 'styled-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';
import { GatsbyImage } from 'gatsby-plugin-image';
import { BODY1 } from '@tuunetech/tuune-components';

export const InfluencerWrapper = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  width: 100%;
  padding: 64px 0;
  ${GradientOrSolidBgCss};
`;

export const Quote = styled.div`
  background-color: #ffe9cb;
  border-radius: 4px;

  padding: 16px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 40px 16px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 30px 38px;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 16px;
  z-index: 1;

  ::after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    background-color: #ffe9cb;
    border-radius: 0 4px;
    transform: rotate(-45deg);
    bottom: -10px;
    left: calc(50% - 20px);
    position: absolute;
    z-index: -1;

    ${({ theme }) => theme.breakpoints.up('md')} {
      transform: rotate(45deg);
      left: -10px;
      top: 30px;
    }
  }
`;

export const Avatar = styled(GatsbyImage)`
  width: 160px;
  height: 160px;
  img {
    border-radius: 50%;
  }
`;

export const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  margin-top: 44px;

  row-gap: 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: column;
    margin-top: 0;
  }

  ${BODY1} {
    font-size: 14px;
    text-transform: uppercase;
  }
`;
