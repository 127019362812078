import React from 'react';
import { Slices, SliceTypes } from '../../types';
import {
  Banner,
  CallToAction,
  HowItWorks,
  Promo,
  Supported,
  Team,
  Testimonials,
} from './styles';
import { KeyValues } from '../slices/KeyValues';
import { SliceWrapper } from '../shared/SliceWrapper';

type CycleTrackingProps = {
  slices: Slices;
};

export const CycleTracking: React.FC<CycleTrackingProps> = ({ slices }) => {
  const handleOpenModal = (klaviyoFormId: string) => () => {
    const _klOnsite = window._klOnsite || [];
    _klOnsite.push(['openForm', klaviyoFormId]);
  };

  const getSlice = (slice: typeof slices[number]) => {
    let klaviyoFormId;
    if (slice.primary && 'klaviyo_form_id' in slice.primary) {
      klaviyoFormId = slice.primary.klaviyo_form_id;
    }
    const onClickFunc = klaviyoFormId
      ? handleOpenModal(klaviyoFormId)
      : undefined;
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} onClick={onClickFunc} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.HIW:
        return (
          <HowItWorks
            {...slice.primary}
            items={slice.items}
            onClick={onClickFunc}
          />
        );
      case SliceTypes.Team:
        return <Team teamMembers={slice.items} {...slice.primary} />;
      case SliceTypes.Promo:
        return <Promo {...slice.primary} onClick={onClickFunc} />;
      case SliceTypes.CTA:
        return <CallToAction {...slice.primary} onClick={onClickFunc} />;
      case SliceTypes.Testimonials:
        return (
          <Testimonials
            {...slice.primary}
            testimonials={slice.items}
            onClick={onClickFunc}
          />
        );
      case SliceTypes.Supported:
        return <Supported {...slice.primary} logos={slice.items} />;
    }
  };

  return (
    <>
      {slices.map(slice => (
        <SliceWrapper id={slice.id} key={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </>
  );
};
