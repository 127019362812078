import React from 'react';
import { GridContainer, GridItem } from '@tuunetech/tuune-components';
import { KlaviyoT4CDemoFormPrimary } from '../../../types';
import { Wrapper } from './styles';

export const KlaviyoT4CDemoForm: React.FC<KlaviyoT4CDemoFormPrimary> = ({
  bg_color,
  id,
}) => {
  return (
    <Wrapper $bgColor={bg_color} id={id}>
      <GridContainer justifyContent="center" $disableNegativeMargins>
        <GridItem xs={12} md={8} lg={6}>
          <div className="klaviyo-form-T59TG4" />
        </GridItem>
      </GridContainer>
    </Wrapper>
  );
};
