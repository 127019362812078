import React, { ReactNode } from 'react';
import { InfluencerPrimary, SliceTypes } from '../../../types';
import { Avatar, AvatarContainer, InfluencerWrapper, Quote } from './styles';
import {
  BODY1,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Elements, HTMLSerializer, RichText } from 'prismic-reactjs';
import { commonHtmlSerializer } from '../commonHtmlSerializer';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { ButtonLink } from '../../shared/ButtonLink';

export const buttonHtmlSerializer: HTMLSerializer<ReactNode> = (
  type,
  element,
  key,
) => {
  if (type === Elements.image) {
    return (
      <img alt="icon" key={key} src={element.url} {...element.dimensions} />
    );
  }
  return <></>;
};

export const Influencer: React.FC<
  InfluencerPrimary & { className?: string }
> = ({
  bg_color,
  linear_gradient,
  quote,
  avatar,
  account,
  button_link,
  complex_button_text,
  button_color,
  button_variant,
  className,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const renderButton = () => {
    return (
      button_link?.url &&
      complex_button_text?.text && (
        <ButtonLink
          url={button_link.url}
          text={complex_button_text.text}
          sliceName={SliceTypes.Influencer}
          color={button_color}
          variant={button_variant}
          startIcon={
            <RichText
              render={complex_button_text.raw}
              htmlSerializer={buttonHtmlSerializer}
            />
          }
        />
      )
    );
  };

  return (
    <InfluencerWrapper
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="md">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10}>
            <Box
              display="flex"
              flexDirection={isDesktop ? 'row-reverse' : 'column'}
              gridColumnGap="57px"
            >
              <Quote>
                <RichText
                  render={quote?.raw}
                  htmlSerializer={commonHtmlSerializer}
                />
                {isDesktop && renderButton()}
              </Quote>
              <AvatarContainer>
                {!isDesktop && renderButton()}
                {avatar && <Avatar image={getImage(avatar)} />}
                <BODY1>{account}</BODY1>
              </AvatarContainer>
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </InfluencerWrapper>
  );
};
