import React, { useEffect } from 'react';
import { Slices, SliceTypes } from '../../types';
import {
  AlternateTiles,
  HiwAlternateTiles,
  HowItWorks,
  Supported,
  Testimonials,
} from './styles';
import { Banner } from '../slices/Banner';
import { KeyValues } from '../slices/KeyValues';
import { FAQ } from '../slices/FAQ';
import { CMPFrame } from '../slices/CMPFrame';
import { Compare } from '../HTLanding/styles';
import { StepGuide } from '../slices/StepGuide';
import { SliceWrapper } from '../shared/SliceWrapper';
import { Purchase } from '../slices/Purchase';
import { Options } from '../slices/Options';
import {
  ECOMMERCE_BIRTH_CONTROL_PRODUCT,
  ENHANCED_ECOMMERCE_EVENT,
} from 'utils/analytics/constants';
import { useAnalytics } from 'utils/analytics';

type BCLandingProps = {
  slices: Slices;
};

export const BCLanding: React.FC<BCLandingProps> = ({ slices }) => {
  const analytics = useAnalytics();

  useEffect(() => {
    analytics.logEnhancedEcommerceEvent(
      ENHANCED_ECOMMERCE_EVENT.VIEW_ITEM,
      ECOMMERCE_BIRTH_CONTROL_PRODUCT,
    );
  }, [analytics]);

  const getSlice = (slice: typeof slices[number]) => {
    switch (slice.slice_type) {
      case SliceTypes.Banner:
        return <Banner {...slice.primary} />;
      case SliceTypes.Purchase:
        return <Purchase {...slice.primary} />;
      case SliceTypes.HIW:
        return <HowItWorks {...slice.primary} items={slice.items} />;
      case SliceTypes.KeyValues:
        return <KeyValues {...slice.primary} items={slice.items} />;
      case SliceTypes.FAQ:
        return <FAQ {...slice.primary} qas={slice.items} />;
      case SliceTypes.CMPFrame:
        return <CMPFrame />;
      case SliceTypes.Compare:
        return <Compare {...slice.primary} statements={slice.items} />;
      case SliceTypes.StepGuide:
        return <StepGuide {...slice.primary} steps={slice.items} />;
      case SliceTypes.Supported:
        return <Supported {...slice.primary} logos={slice.items} />;
      case SliceTypes.Testimonials:
        return <Testimonials {...slice.primary} testimonials={slice.items} />;
      case SliceTypes.AlternateTiles:
        return slice.primary.content?.text ? (
          <HiwAlternateTiles {...slice.primary} tiles={slice.items} />
        ) : (
          <AlternateTiles {...slice.primary} tiles={slice.items} />
        );
      case SliceTypes.Options:
        return <Options {...slice.primary} options={slice.items} />;
    }
  };
  return (
    <>
      {slices.map(slice => (
        <SliceWrapper key={slice.id} id={slice.primary.id}>
          {getSlice(slice)}
        </SliceWrapper>
      ))}
    </>
  );
};
