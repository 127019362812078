import styled, { css } from 'styled-components';
import { Banner as BaseBanner } from '../slices/Banner';
import { HowItWorks as BaseHowItWorks } from '../slices/HowItWorks';
import { ListItemIndicator } from '../slices/HowItWorks/styles';
import { Team as BaseTeam } from '../slices/Team';
import { PersonContainer } from '../shared/Person/styles';
import { BODY1, BODY2, Button } from '@tuunetech/tuune-components';
import { Promo as BasePromo } from '../slices/Promo';
import { PromoContainer } from '../slices/Promo/styles';
import { CallToAction as BaseCallToAction } from '../slices/CallToAction';
import { Testimonials as BaseTestimonials } from '../slices/Testimonials';
import { Supported as BaseSupported } from '../slices/Supported';

const ButtonColorCss = css`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
    }
  }

  .MuiButton-outlinedPrimary {
    color: #613ac7;
    border-color: #613ac7;
  }
`;

export const Banner = styled(BaseBanner)`
  ${ButtonColorCss};
`;

export const HowItWorks = styled(BaseHowItWorks)`
  ${ButtonColorCss};

  img {
    border-radius: 50%;
  }

  ${ListItemIndicator} {
    background-color: #613ac7;
  }

  strong {
    display: block;
    margin-bottom: 4px;
  }
`;

export const Team = styled(BaseTeam)`
  ${BODY1} {
    color: ${({ theme }) => theme.palette.common.black};
  }
  ${PersonContainer} {
    ${BODY2}:last-child {
      font-style: italic;
    }

    ${BODY2} {
      color: ${({ theme }) => theme.palette.common.grey[600]};
    }
  }
`;

export const Promo = styled(BasePromo)`
  ${PromoContainer} {
    margin-bottom: 0;
  }
`;

export const CallToAction = styled(BaseCallToAction)`
  ${ButtonColorCss};

  .MuiTypography-root {
    color: ${({ theme }) => theme.palette.common.black};
  }

  ${Button} {
    padding: 12px 20px !important;
  }
`;

export const Testimonials = styled(BaseTestimonials)`
  ${ButtonColorCss};
`;

export const Supported = styled(BaseSupported)`
  margin: 0;
  padding: 16px 0;
`;
