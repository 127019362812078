import styled from 'styled-components';
import { BODY1, H2, H4 } from '@tuunetech/tuune-components';
import { Text as BaseText, Wrapper } from '../slices/Text';
import { KeyValues as BaseKeyValues } from '../slices/KeyValues';
import { KeyValuesContainer, Value } from '../slices/KeyValues/styles';

export const Text = styled(BaseText)`
  ${BODY1} + ${H2} {
    margin-top: 64px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding-top: 120px;
    text-align: center;

    ${BODY1} + ${H2} {
      margin-top: 120px;
    }

    ${Wrapper} {
      padding-top: 120px;
    }
  }
`;

export const KeyValues = styled(BaseKeyValues)`
  ${H2}, ${BODY1} {
    text-align: center;
  }

  ${KeyValuesContainer} {
    flex-direction: column;
  }

  ${H4} {
    color: #613ac7;
    margin-bottom: 16px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${Value} {
      width: 100%;
      margin: 0 0 24px 0;
    }
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${KeyValuesContainer} {
      flex-direction: row;
    }

    ${Value} {
      align-items: flex-start;
      text-align: left;
    }
  }
`;
