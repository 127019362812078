import React, { useEffect } from 'react';

export const CMPFrame: React.FC = () => {
  const handleResizeSlice = (e: MessageEvent) => {
    const iframe: HTMLIFrameElement | null = document.querySelector(
      '#cmp-iframe',
    );
    if (iframe) {
      // message that was passed from iframe page
      const message = e.data;
      iframe.style.height = message.height + 'px';
    }
  };

  useEffect(() => {
    window.addEventListener('message', handleResizeSlice, false);
    return () => {
      window.removeEventListener('message', handleResizeSlice);
    };
  }, []);

  const CMP_LINK =
    process.env.GATSBY_ENV === 'develop'
      ? 'http://localhost:8001/'
      : 'https://checkmypill.tuune.com/';

  return <iframe id="cmp-iframe" width="100%" src={CMP_LINK} frameBorder="0" />;
};
