import styled from 'styled-components';
import { Button, H2, H3 } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from 'utils/GlobalStyle';

export const PromoOuterContainer = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  width: 100%;
`;

export const PromoContainer = styled.div<{
  $contentPositionTop: boolean;
  $withBg: boolean;
}>`
  position: relative;
  margin: ${$withBg => ($withBg ? '64px 0' : '40px 0')};
  display: flex;
  justify-content: center;
  align-items: ${({ $contentPositionTop }) =>
    $contentPositionTop ? 'flex-start' : 'flex-end'};
  border-radius: 40px;
  overflow: hidden;
  z-index: 2;
`;

export const Content = styled.div<{ $color: string; $textAlign: string }>`
  position: absolute;
  text-align: ${({ $textAlign }) => $textAlign};

  color: ${({ $color }) => $color};
  padding: 32px;
  width: 100%;

  ${H2}, ${H3} {
    margin-bottom: 8px;
  }

  ${Button} {
    margin-top: 24px;
  }
`;
