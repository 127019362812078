import styled from 'styled-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const CompareWrapper = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  width: 100%;
  padding: 48px 0;
  ${GradientOrSolidBgCss};

  ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 64px 0;
  }
`;

export const Row = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.palette.common.grey[300]};
  display: flex;
  padding: 16px 0;
  column-gap: 16px;
  align-items: center;
`;

export const StatementCol = styled.div`
  width: 100%;
`;

export const ValueCol = styled.div<{ $value?: boolean }>`
  width: 72px;
  text-align: center;

  .MuiSvgIcon-root {
    color: ${({ $value }) => ($value ? '#8D6BCE' : '#CDBDE9')};
  }
`;
