import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Container, H1, Button, H3, BODY1 } from '@tuunetech/tuune-components';

export const Hero = styled(GatsbyImage)`
  width: 100%;
  max-height: 736px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    max-height: 884px;
  }
`;

export const Title = styled(H1)`
  white-space: pre-wrap;
  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-bottom: 55px;
  }
`;

export const Content = styled(Container)`
  padding-bottom: 32px;
  padding-top: 32px;
  position: absolute;
  height: calc(100% - 64px);
  bottom: 0;
  display: flex;
  align-items: flex-end;
  ${({ theme }) => theme.breakpoints.up('lg')} {
    align-items: center;
  }

  ${Title} + ${H3},
  ${Title} + ${BODY1} {
    margin-top: 24px;
  }

  ${H3} + ${H3},
  ${BODY1} + ${BODY1},
  ${H3} + ${Button},
  ${BODY1} + ${Button},
  ${Title} + ${Button} {
    margin-top: 24px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    ${Title} + ${H3},
    ${Title} + ${BODY1},
    ${H3} + ${Button},
    ${BODY1} + ${Button},
    ${Title} + ${Button} {
      margin-top: 55px;
    }
  }
`;

export const BannerContainer = styled.div<{ $color: string }>`
  width: 100%;
  color: ${({ $color }) => $color};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  ${H1} {
    margin-bottom: 24px;
  }
`;
