import styled from 'styled-components';
import { PrimaryButtonColorCss } from 'utils/GlobalStyle';
import { HowItWorksStack } from '../slices/HowItWorks/HowItWorksStack';
import { Avatar, ItemsContainer } from '../slices/HowItWorks/styles';
import { QuoteTestimonials as BaseQuoteTestimonials } from '../slices/Testimonials/QuoteTestimonials';
import { PersonContainer } from '../shared/Person/styles';
import { KeyValues as BaseKeyValues } from '../slices/KeyValues';
import { Value } from '../slices/KeyValues/styles';
import { Testimonials as BaseTestimonials } from '../slices/Testimonials';
import { Supported as BaseSupported } from '../slices/Supported';
import { SupportedContainer } from '../slices/Supported/styles';

export const HowItWorks = styled(HowItWorksStack)`
  ${Avatar} {
    width: 100px;
    height: 100px;
    border-radius: 0;
  }

  ${ItemsContainer} {
    margin-top: 32px;
    margin-bottom: 48px;
  }
`;

export const QuoteTestimonials = styled(BaseQuoteTestimonials)`
  ${PrimaryButtonColorCss};
  ${PersonContainer} {
    margin-top: -40px;
  }
`;

export const Testimonials = styled(BaseTestimonials)`
  ${PrimaryButtonColorCss};
  ${PersonContainer} {
    align-items: center;
  }
`;

export const KeyValues = styled(BaseKeyValues)`
  ${Value} {
    flex-direction: column;
    text-align: center;
    white-space: normal;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      white-space: nowrap;
    }
  }
`;

export const Supported = styled(BaseSupported)`
  ${SupportedContainer} {
    padding-top: 88px;
    padding-bottom: 32px;
  }
`;
