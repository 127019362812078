import styled from 'styled-components';
import { BODY1 } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const Header = styled.div`
  text-align: left;
  ${({ theme }) => theme.breakpoints.up('md')} {
    text-align: center;
  }

  ${BODY1} {
    margin-top: 16px;
  }
`;

export const StepIndicator = styled.div`
  height: 20px;
  min-width: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 700;
  background-color: ${({ theme }) => theme.palette.common.purple[50]};
  color: ${({ theme }) => theme.palette.common.purple[900]};
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 2px;
`;

export const StepGuideContainer = styled.div<{ $bgColor: string }>`
  ${GradientOrSolidBgCss};
  width: 100%;
`;
