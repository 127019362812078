import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';

export const SocialsContainer = styled(Container)`
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  margin: 40px 0;
`;

export const Icon = styled.div`
  cursor: pointer;
  & + & {
    margin-left: 40px;
  }
`;
