import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss, PrimaryButtonColorCss } from 'utils/GlobalStyle';

export const TileImage = styled.div<{ $isRight: boolean }>`
  margin-left: ${({ $isRight }) => ($isRight ? 'auto' : '0')};
  max-height: 100%;
`;

export const TilesContainer = styled(Container)<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  ${PrimaryButtonColorCss};
  padding: 64px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 106px 0;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${TileImage} {
      margin-bottom: 24px;
    }
  }
`;

export const Tile = styled.div`
  width: 100%;
  margin: auto;
  & + & {
    margin-top: 64px;
  }
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  column-gap: 64px;
  row-gap: 48px;

  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: row;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    & + & {
      margin-top: 160px;
    }
  }
`;

export const Item = styled.div<{ $isImage: boolean }>`
  p > .MuiLink-root {
    margin-left: 5px;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 50%;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    width: ${({ $isImage }) => ($isImage ? 'fit-content' : '100%')};
  }
`;
