import styled from 'styled-components';
import { Button } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from 'utils/GlobalStyle';

export const OuterContainer = styled.div<{
  $textColor: string;
  $bgColor: string;
  $gradient?: string;
}>`
  width: 100%;
  color: ${({ $textColor }) => $textColor};
  text-align: center;

  ${GradientOrSolidBgCss};
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 24px;

  ${({ theme }) => theme.breakpoints.up(1200)} {
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    column-gap: 40px;
  }
`;

export const SupportedContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 64px 0;
  ${({ theme }) => theme.breakpoints.up('md')} {
    padding: 106px 0;
  }

  ${Button} {
    margin-top: 36px;
  }
`;

export const Logo = styled.div`
  img {
    max-height: 32px;
    width: auto;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    img {
      height: 40px;
    }
  }
`;

export const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 15px;
  column-gap: 24px;
  row-gap: 36px;
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    column-gap: 42px;
    margin-top: 0;
    padding: 0;
  }
`;
