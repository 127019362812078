import React from 'react';
import { SymptomsItems, SymptomsPrimary } from 'types';
import {
  BODY1,
  Container,
  GridContainer,
  GridItem,
  useScrollAndDrag,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { RichText } from 'prismic-reactjs';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { SymptomBlock, ScrollableContainer, SymptomsWrapper } from './styles';
import { commonHtmlSerializer } from '../commonHtmlSerializer';

export const Symptoms: React.FC<
  SymptomsPrimary & { symptoms?: SymptomsItems[] }
> = ({ title, symptoms }) => {
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const sliderRef = useScrollAndDrag();

  return (
    <Container maxWidth="lg">
      <GridContainer justifyContent="center">
        <GridItem xs={12} md={10} xl={12}>
          <Box height="40px" />
          <RichText render={title.raw} htmlSerializer={commonHtmlSerializer} />
          <Box height="24px" />
          <ScrollableContainer ref={sliderRef}>
            <SymptomsWrapper>
              {symptoms?.map(({ image, symptom_name }) => {
                const sizedImage = getImage(isLG ? image : image.thumbnails.xs);
                return (
                  <SymptomBlock key={symptom_name.text}>
                    {sizedImage && (
                      <GatsbyImage alt={image.alt} image={sizedImage} />
                    )}
                    <BODY1>{symptom_name.text}</BODY1>
                  </SymptomBlock>
                );
              })}
            </SymptomsWrapper>
          </ScrollableContainer>
        </GridItem>
      </GridContainer>
    </Container>
  );
};
