import React, { useMemo } from 'react';
import {
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { AlternateTilesItems, AlternateTilesPrimary, SliceTypes } from 'types';
import { navigate } from 'gatsby-link';
import { getUrl } from 'utils/helpers';
import { TilesContainer, Tile, TileImage, Item } from './styles';
import { ButtonLink } from '../../shared/ButtonLink';
import { ContentRender } from '../ContentRender';

export const AlternateTiles: React.FC<
  AlternateTilesPrimary & { tiles?: AlternateTilesItems[]; className?: string }
> = ({
  bg_color,
  linear_gradient,
  image_first,
  mobile_image_first,
  tiles,
  className,
  button_text,
  button_link,
  button_color,
  button_variant,
  content,
}) => {
  const isLG = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isMD = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));
  const isSM = useMediaQuery((theme: Theme) => theme.breakpoints.up(414));

  const remappedTiles = useMemo(() => {
    if (tiles?.length) {
      return tiles.map(({ image: { alt, thumbnails }, ...tileProps }) => ({
        alt,
        image: isLG
          ? getImage(thumbnails.lg)
          : isMD
          ? getImage(thumbnails.md)
          : isSM
          ? getImage(thumbnails.sm)
          : getImage(thumbnails.xs),
        ...tileProps,
      }));
    }
  }, [tiles, isLG, isMD, isSM]);

  const renderImage = (
    alt?: string,
    image?: IGatsbyImageData,
    isRight = false,
  ) => {
    return image ? (
      <TileImage $isRight={isRight}>
        <GatsbyImage alt={alt || ''} image={image} />
      </TileImage>
    ) : null;
  };

  const renderContent = ({
    content,
    button_variant,
    button_color,
    button_link,
    button_text,
  }: Partial<AlternateTilesItems>) => {
    return (
      <>
        <ContentRender content={content} />
        {button_link?.url && button_text && (
          <>
            <Box height="24px" />
            <Button
              variant={button_variant}
              color={button_color}
              onClick={() => navigate(getUrl(button_link.url))}
            >
              {button_text}
            </Button>
          </>
        )}
      </>
    );
  };

  return (
    <TilesContainer
      $gradient={linear_gradient}
      $bgColor={bg_color}
      fixed={false}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={12} lg={10} xl={12}>
            {content?.text && (
              <Box textAlign={isMD ? 'center' : 'start'}>
                <ContentRender content={content} />
                <Box height="48px" />
              </Box>
            )}
            {remappedTiles?.map(({ image, alt, ...tileProps }, idx) => {
              let isImageFirst = image_first;
              // for odd lines alternate tile
              if (idx % 2 === 1) isImageFirst = !isImageFirst;
              // on mobile screens put image first
              if (!isMD) isImageFirst = mobile_image_first;

              return (
                <Tile key={idx}>
                  <Item $isImage={isImageFirst}>
                    {isImageFirst
                      ? renderImage(alt, image)
                      : renderContent(tileProps)}
                  </Item>
                  <Item $isImage={!isImageFirst}>
                    {isImageFirst
                      ? renderContent(tileProps)
                      : renderImage(alt, image, true)}
                  </Item>
                </Tile>
              );
            })}
            {button_text && button_link.url && (
              <Container>
                <Box display="flex" justifyContent="center" marginTop="40px">
                  <ButtonLink
                    url={button_link.url}
                    target={button_link.target}
                    text={button_text}
                    sliceName={SliceTypes.Testimonials}
                    color={button_color}
                    variant={button_variant}
                    fullWidth={!isMD}
                  />
                </Box>
              </Container>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </TilesContainer>
  );
};
