import React from 'react';
import { TextPrimary } from 'types';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { ContentRender } from '../ContentRender';
import styled from 'styled-components';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';

export const Wrapper = styled.div<{ $bgColor: string; $gradient: string }>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 64px 0;
  ${GradientOrSolidBgCss};
`;

export const Text: React.FC<TextPrimary & { className?: string }> = ({
  content,
  className,
  bg_color,
  linear_gradient,
}) => {
  return (
    <Wrapper
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} lg={12}>
            <ContentRender content={content} />
          </GridItem>
        </GridContainer>
      </Container>
    </Wrapper>
  );
};
