import React, { useMemo } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import { Container } from '@tuunetech/tuune-components';
import { SliceTypes, SupportedItems, SupportedPrimary } from 'types';
import {
  Logo,
  LogosContainer,
  OuterContainer,
  SupportedContainer,
  ContentContainer,
} from './styles';
import { ButtonLink } from 'components/shared/ButtonLink';
import { commonHtmlSerializer } from '../commonHtmlSerializer';

export const Supported: React.FC<
  SupportedPrimary & { logos?: SupportedItems[]; className?: string }
> = ({
  content,
  logos,
  button_text,
  button_link,
  button_color,
  button_variant,
  text_color,
  bg_color,
  linear_gradient,
  className,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const remappedLogos = useMemo(() => {
    if (logos?.length) {
      return logos.map(({ logo: { alt, thumbnails }, logo }) => ({
        alt,
        image: isDesktop ? getImage(logo) : getImage(thumbnails.xs),
      }));
    }
  }, [logos, isDesktop]);

  return (
    <OuterContainer
      $textColor={text_color}
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container maxWidth="lg" fixed={false}>
        <SupportedContainer>
          <ContentContainer>
            {content?.text && (
              <RichText
                render={content.raw}
                htmlSerializer={commonHtmlSerializer}
              />
            )}
            {remappedLogos?.length && (
              <LogosContainer>
                {remappedLogos?.map(
                  ({ alt = '', image }) =>
                    image && (
                      <Logo key={alt}>
                        <GatsbyImage alt={alt} image={image} />
                      </Logo>
                    ),
                )}
              </LogosContainer>
            )}
          </ContentContainer>
          {button_link?.url && button_text && (
            <ButtonLink
              url={button_link.url}
              target={button_link.target}
              text={button_text}
              sliceName={SliceTypes.Supported}
              color={button_color}
              variant={button_variant}
            />
          )}
        </SupportedContainer>
      </Container>
    </OuterContainer>
  );
};
