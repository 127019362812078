import React from 'react';
import { HiwItems, HiwPrimary, SliceTypes } from 'types';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { ContentRender } from '../ContentRender';
import {
  BackgroundContainer,
  Avatar,
  ListItemIndicator,
  ItemsContainer,
  ContentBlock,
} from './styles';
import { ButtonLink } from '../../shared/ButtonLink';

export const HowItWorks: React.FC<
  HiwPrimary & { items?: HiwItems[]; className?: string }
> = ({
  items,
  button_link,
  button_variant,
  button_color,
  button_text,
  bg_color,
  title,
  onClick,
  linear_gradient,
  className,
}) => {
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <BackgroundContainer
      $bgColor={bg_color}
      $gradient={linear_gradient}
      className={className}
    >
      <Container $verticalPadding={64} maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} lg={12}>
            <Box textAlign={isDesktop ? 'center' : 'left'}>
              <ContentRender content={title} />
            </Box>
            <ItemsContainer>
              {items?.map(({ image, description }, index) => {
                const avatarImage = getImage(image);
                return (
                  <Box
                    key={image.alt}
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                    alignItems="center"
                    marginTop="56px"
                    width="100%"
                  >
                    {avatarImage && (
                      <Avatar alt={image.alt} image={avatarImage} />
                    )}
                    <Box textAlign="left">
                      <ContentBlock
                        startAdornment={
                          <ListItemIndicator> {index + 1} </ListItemIndicator>
                        }
                        content={<ContentRender content={description} />}
                      />
                    </Box>
                  </Box>
                );
              })}
            </ItemsContainer>
            {button_text && button_link?.url && (
              <Box display="flex" justifyContent="center" marginTop="56px">
                <ButtonLink
                  url={button_link.url}
                  target={button_link.target}
                  text={button_text}
                  sliceName={SliceTypes.HIW}
                  variant={button_variant}
                  color={button_color}
                  fullWidth={!isDesktop}
                  onClick={onClick}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </BackgroundContainer>
  );
};
