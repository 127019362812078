import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from 'utils/GlobalStyle';
import { BODY2 } from '@tuunetech/tuune-components';

export const BackgroundContainer = styled(Container)<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  width: 100%;
`;

export const TabContent = styled(BODY2)<{ $color: string }>`
  color: ${({ $color }) => $color};
  margin-bottom: 16px;
`;
