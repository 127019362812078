import styled from 'styled-components';
import { Testimonials } from './index';
import {
  Avatar as TestimonialsAvatar,
  PersonContainer,
} from '../../shared/Person/styles';

export const QuoteTestimonials = styled(Testimonials)`
  text-align: center;

  ${PersonContainer} {
    align-items: center;

    ${TestimonialsAvatar} {
      margin-bottom: -20px;
    }
  }
`;
