import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GradientOrSolidBgCss } from '../../../utils/GlobalStyle';
import { ContentBlock as BaseContentBlock } from '@tuunetech/tuune-components';

export const Avatar = styled(GatsbyImage)`
  width: 160px;
  height: 160px;
  margin-bottom: 16px;
`;

export const ListItemIndicator = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.75rem;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const BackgroundContainer = styled.div<{
  $bgColor: string;
  $gradient?: string;
}>`
  ${GradientOrSolidBgCss};
  width: 100%;
`;

export const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  column-gap: 26px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    flex-direction: row;
  }
`;

export const ContentBlock = styled(BaseContentBlock)``;
