import styled, { css } from 'styled-components';
import { Banner as BaseBanner } from '../slices/Banner';
import { Promo as BasePromo } from '../slices/Promo';
import { StepGuide as BaseStepGuide } from '../slices/StepGuide';
import { StepIndicator } from '../slices/StepGuide/styles';
import { AlternateTiles as BaseAlternateTiles } from '../slices/AlternateTiles';
import { TileImage } from '../slices/AlternateTiles/styles';
import { Compare as BaseCompare } from '../slices/Compare';
import { Influencer as BaseInfluencer } from '../slices/Influencer';
import { CallToAction as BaseCallToAction } from '../slices/CallToAction';
import { ImageWrapper } from '../slices/CallToAction/styles';
import { HowItWorksStack } from '../slices/HowItWorks/HowItWorksStack';
import { QuoteTestimonials } from '../slices/Testimonials/QuoteTestimonials';
import { Testimonials as BaseTestimonials } from '../slices/Testimonials';
import { Avatar } from '../slices/HowItWorks/styles';

const ButtonColorCss = css`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #613ac7;
      color: #ffffff;
    }
  }

  .MuiButton-textPrimary {
    color: #613ac7;
  }
`;

export const Banner = styled(BaseBanner)`
  .MuiButton-containedPrimary {
    &,
    &:hover {
      background-color: #fedeb3;
      color: #000000;
    }
  }
`;

export const HowItWorks = styled(HowItWorksStack)`
  ${Avatar} {
    width: 100px;
    height: 100px;
  }
`;

export const Promo = styled(BasePromo)``;

export const StepGuide = styled(BaseStepGuide)`
  ${ButtonColorCss};

  ${StepIndicator} {
    background-color: #fedeb3;
    color: #000000;
  }
`;

export const AlternateTiles = styled(BaseAlternateTiles)`
  ${ButtonColorCss};

  ${TileImage} {
    width: 320px;
    max-width: 100%;
  }
`;

export const CustomTestimonials = styled(QuoteTestimonials)`
  padding: 0;
`;

export const Compare = styled(BaseCompare)`
  ${ButtonColorCss};
`;

export const Influencer = styled(BaseInfluencer)`
  ${ButtonColorCss};

  padding-bottom: 0;
`;

export const CallToAction = styled(BaseCallToAction)`
  text-align: center;
  padding-bottom: 0;

  ${ImageWrapper} {
    margin-bottom: 16px;
  }
`;

export const Testimonials = styled(BaseTestimonials)`
  ${ButtonColorCss};
`;
