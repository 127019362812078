import React, { ChangeEvent, useCallback } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  BODY1,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { RichText } from 'prismic-reactjs';
import { FaqItems, FaqPrimary, SliceTypes } from 'types';
import { TitleParagraphsGroup } from 'components/shared';
import { useLocation } from '@reach/router';
import { FaqContainer, Divider } from './styles';
import { htmlSerializer } from './htmlSerializer';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { FAQ_ITEM_OPEN } from '../../../utils/analytics/constants';
import { ButtonLink } from '../../shared/ButtonLink';
import { useAnalytics } from 'utils/analytics';

export const FAQ: React.FC<FaqPrimary & { qas?: FaqItems[] }> = ({
  bg_color,
  linear_gradient,
  title,
  paragraphs,
  qas,
  button_text,
  button_link,
  button_variant,
  button_color,
}) => {
  const location = useLocation();
  const analytics = useAnalytics();
  const handleAccordionChange = useCallback(
    (isOpen, qText) => {
      if (isOpen) {
        analytics.track(FAQ_ITEM_OPEN, {
          item: qText,
          pageUrl: location.pathname,
        });
      }
    },
    [location.pathname, analytics],
  );
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <FaqContainer $bgColor={bg_color} $gradient={linear_gradient}>
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10} xl={12}>
            <TitleParagraphsGroup
              title={title.raw}
              paragraphs={paragraphs?.raw}
              alignLeft
            />
            <Box marginTop="36px">
              {qas?.map(({ q, a }) => (
                <React.Fragment key={q.text}>
                  <Divider />
                  <Accordion
                    onChange={(e: ChangeEvent, expanded: boolean) =>
                      handleAccordionChange(expanded, q.text)
                    }
                  >
                    <AccordionSummary $verticalPadding={2}>
                      <BODY1>{q.text}</BODY1>
                    </AccordionSummary>
                    <AccordionDetails $paddingBottom={16}>
                      <div>
                        <RichText
                          render={a.raw}
                          htmlSerializer={htmlSerializer}
                        />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </React.Fragment>
              ))}
            </Box>
            {button_text && button_link?.url && (
              <Box
                marginTop="40px"
                display={!isDesktop ? 'block' : 'flex'}
                justifyContent="center"
              >
                <ButtonLink
                  url={button_link.url}
                  target={button_link.target}
                  text={button_text}
                  sliceName={SliceTypes.FAQ}
                  variant={button_variant}
                  color={button_color}
                  fullWidth={!isDesktop}
                />
              </Box>
            )}
          </GridItem>
        </GridContainer>
      </Container>
    </FaqContainer>
  );
};
