import styled from 'styled-components';
import { HowItWorks } from './index';
import { ContentBlock, ItemsContainer } from './styles';

export const HowItWorksStack = styled(HowItWorks)`
  ${ItemsContainer} {
    flex-direction: column;
    margin: 0 auto;
    .MuiTypography-root {
      text-align: center;
    }
  }

  ${({ theme }) => theme.breakpoints.up('lg')} {
    ${ItemsContainer} {
      flex-direction: row;
    }
  }

  ${ContentBlock} {
    > div:first-of-type {
      display: none;
    }
  }
`;
