import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';
import { GradientOrSolidBgCss } from 'utils/GlobalStyle';

export const CTAContainer = styled(Container)<{
  $bgColor: string;
  $gradient?: string;
  $removeTopMargin?: boolean;
}>`
  background-color: ${({ $bgColor }) => $bgColor};
  ${GradientOrSolidBgCss};
  padding: ${({ $removeTopMargin }) =>
    $removeTopMargin ? '0 0 64px 0' : '64px 0'};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 36px;
`;
