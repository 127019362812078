import React from 'react';
import { PersonPrimary } from '../../../types';
import { PersonContainer, Avatar } from './styles';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Theme, useMediaQuery } from '@material-ui/core';
import { getImage } from 'gatsby-plugin-image';
import { RichText } from 'prismic-reactjs';
import {
  commonHtmlSerializer,
  commonHtmlSerializerSmallText,
} from '../commonHtmlSerializer';

export const Person: React.FC<PersonPrimary & { className?: string }> = ({
  content,
  avatar,
  bg_color,
  linear_gradient,
  quote,
  summary,
  className,
}) => {
  const avatarImg = getImage(avatar);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <PersonContainer
      $gradient={linear_gradient}
      $bgColor={bg_color}
      className={className}
    >
      <Container maxWidth="lg">
        <GridContainer justifyContent="center">
          <GridItem xs={12} md={10}>
            <Box textAlign="center" marginBottom="64px">
              <RichText
                render={content?.raw}
                htmlSerializer={commonHtmlSerializer}
              />
            </Box>
            <Box
              display="flex"
              flexDirection={isDesktop ? 'row' : 'column'}
              alignItems="center"
            >
              {avatarImg && <Avatar alt={avatar.alt} image={avatarImg} />}
              <Box>
                <RichText
                  htmlSerializer={commonHtmlSerializerSmallText}
                  render={summary.raw}
                />
                <Box marginTop="40px" textAlign="left">
                  <RichText
                    htmlSerializer={commonHtmlSerializer}
                    render={quote.raw}
                  />
                </Box>
              </Box>
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </PersonContainer>
  );
};
